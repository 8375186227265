<template>
  <div class="cont">
    <div class="gongxin"><span style="opacity: 1" @click="beianClick">沪ICP备16009033号-13</span></div>
    <Navbar :dialogLoginVisible.sync="loginFormShow" :needRecharge="needRecharge"></Navbar>
    <el-container style="height: 100%">
      <NavbarLeft active_idx="/" v-if="shownav" style="position: fixed; left: 0"></NavbarLeft>
      <el-main style="height: 100%; padding-left: 252px">
        <div class="top_cont">
          <!-- <el-image style="width: 900px; height: 100%;margin-right: 16px;    flex: 1;background: #f6c0b1;border-radius: 18px;" 
          :src="require('../assets/img/home/banner.png')"
          fit="contain" @click="ai_changeface_click"> 
            <div slot="placeholder" class="image-slot"></div>
          </el-image> -->
          <!-- <div  style="width: 900px; height: 100%;margin-right: 16px;    flex: 1;border-radius: 18px;" class="banner">

          </div> -->

          <el-carousel height="312px" class="banner" style="width: 60%; flex-shrink: 0; margin-right: 16px" @click.native="bannerClick" ref="carousel">
            <el-carousel-item v-for="(item, idx) in bannerList" :key="idx" style="background-size: 100% 100%" :style="{ backgroundImage: 'url(' + item.icon + ')' }"></el-carousel-item>
          </el-carousel>

          <div style="width: 40%; position: relative">
            <div style="background: #222337; height: 38px; width: 100%; display: flex; border-radius: 19px; padding: 0 12px">
              <el-image style="width: 24px; height: 24px; margin-right: 5px; margin-top: 7px" :src="require('../assets/img/home/trumpet.png')" fit="contain">
                <div slot="placeholder" class="image-slot"></div>
              </el-image>
              <el-carousel height="38px" style="width: calc(100% - 7px); overflow-y: hidden; height: 38px; overflow-x: hidden; line-height: 38px" indicator-position="none" arrow="never" direction="vertical">
                <el-carousel-item v-for="(item, idx) in aiNewsList" :key="idx">
                  <div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; font-size: 12px" @click="ainewsclick(item, idx)">
                    {{ item.title }}
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
            <div style="height: calc(100% - 38px); width: 100%; display: flex; justify-content: space-between; margin-top: 16px">
              <!-- <div class="citem1" @click="text_to_img_click">

              </div> -->
              <div class="citem2" @click="ai_changeface_click"></div>
              <div class="citem3" @click="goto_ailucky"></div>
            </div>
          </div>
        </div>
        <!-- <div class="home1 d-flex">
          <el-image style="width: 50px; height: 50px;margin-right: 16px;" 
          :src="require('../assets/img/logo.png')"
          fit="contain">
            <div slot="placeholder" class="image-slot"></div>
          </el-image>
          <span style="margin-left:10px">Boser AI</span>
        </div>
        <div class="home2 d-flex">
          <div style="cursor: pointer;" class="active item">问答 </div>        
          <div class="item" style="cursor: pointer;margin-right: 22px;" @click="ai_changeface_click">AI换脸</div>

          <div class="item" style="cursor: pointer;margin-right: 22px;" @click="img_click">文生图</div>
          <div class="item" style="cursor: pointer;" @click="img_to_img_click">图生图</div>

        </div>
        <div class="home3 d-flex">
          <div class="l d-flex">
            <el-image style="width: 24px; height: 24px;margin-left: 30px;margin-right: 18px;" 
            :src="require('../assets/img/search.png')"
            fit="contain">
              <div slot="placeholder" class="image-slot"></div>
            </el-image>
          </div>

          <el-input v-model="text" :placeholder="placeholderText" class="input" @keyup.enter="search_click" ></el-input>
          <div class="search-btn d-flex"  @click="search_click">
            <el-image style="width: 32px; height: 32px;margin-right: 30px;" 
            :src="require('../assets/img/send.png')"
            fit="contain" >
              <div slot="placeholder" class="image-slot"></div>
            </el-image>
          </div>
        </div> -->
        <div class="hotApply">
          <!-- <div class="_top">
          <div style="flex: 1;">
            热门应用
          <el-image style="width: 22px; height: 22px;margin-left: 2px;" 
            :src="require('../assets/img/textform/fire.png')"
            fit="contain" >
              <div slot="placeholder" class="image-slot"></div>
            </el-image>
          </div>
          <div style="font-size: 16px;color: #9A9AB7;line-height: 22px;display: flex;align-items: center;cursor: pointer;" @click="search_click">
            查看全部       
            <el-image style="width: 14px; height: 14px;margin-left: 2px;" 
            :src="require('../assets/img/textform/gengduo@2x.png')"
            fit="contain" >
              <div slot="placeholder" class="image-slot"></div>
            </el-image>
          </div>
          
        </div> -->
          <div class="bot">
            <!-- <div class="item aireplaceitem"  @click="ai_changeface_click()">
            <div class="top">
              <el-image class="icon"
                :src="require('../assets/img/replaceface/replaceface.png')" fit="contain">
                <div slot="placeholder" class="image-slot"></div>
              </el-image>
              AI换脸
            </div>
            <div class="desc">
              一键探索不同身份，发现新自我
            </div>
            <div class="botbtn">
              去试试
            </div>
          </div> -->

            <div class="item" v-for="(item, idx) in his_list" :key="idx" @click="gotoQaByType(item)">
              <div class="top">
                <el-image class="icon" :src="'https://boserai.com/' + item.title + '@2x.png'" fit="contain">
                  <div slot="placeholder" class="image-slot"></div>
                </el-image>
                {{ item.title }}
              </div>
              <div class="desc">
                {{ item.desc }}
              </div>
              <div class="bottom">{{ item.userNums }}人使用</div>
            </div>
          </div>
        </div>

        <MyWaterfall @loginFormShowChange="loginFormShowChange"></MyWaterfall>
      </el-main>
    </el-container>

    <!-- <div class="waterfall_title">
      AI 广场
    </div> -->

    <LoginForm :dialogLoginVisible.sync="loginFormShow" v-if="loginFormShow"></LoginForm>
    <ElBacktop></ElBacktop>
    <NewRecharge :newRechargeShow.sync="newRechargeShow" v-if="newRechargeShow" @gotoRecharge="gotoRecharge"></NewRecharge>
    <NewMemberRecharge :newMemberRechargeShow.sync="newMemberRechargeShow" v-if="tokenVuex && !isend" @gotoRecharge="gotoRecharge"></NewMemberRecharge>
    <!-- <NewMemberRecharge618 :newMemberRechargeShow.sync="newMemberRechargeShow" v-if="tokenVuex && !isend" @gotoRecharge="gotoRecharge"></NewMemberRecharge618> -->

    <AiNewsPop :ainewsVisible.sync="ainewsVisible" v-if="ainewsVisible" :aiNewsList="aiNewsList" :ainewsIdx="ainewsIdx" @changeaiidx="changeaiidx" @openAinews="openAinews"></AiNewsPop>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import NavbarLeft from '@/components/NavbarLeft.vue'

import common from '../utils/common'
import { square_page_req, img_list_req, save_square_req, square_detail, painting_shareUrl, banner_list_req } from '@/api/square'
import { qa_template_list_req } from '@/api/qa'
import LoginForm from '@/components/LoginForm'
import NewRecharge from '@/components/NewRecharge'
import NewMemberRecharge from '@/components/NewMemberRecharge'
import NewMemberRecharge618 from '@/components/NewMemberRecharge618'

import AiNewsPop from '@/components/AiNewsPop'

import MyWaterfall from '@/components/MyWaterfall'
import ElBacktop from '@/components/ElBacktop'
import { getUUID, timeStampToHumanReadable, getToken } from '@/utils/bose'
import { user_invoke_req, user_account_req, event_log_req, user_countdown_req } from '@/api/user'
import md5 from 'js-md5'

export default {
  name: 'HomeView',
  components: {
    Navbar,
    LoginForm,
    MyWaterfall,
    ElBacktop,
    NewRecharge,
    NewMemberRecharge,
    NavbarLeft,
    AiNewsPop,
    NewMemberRecharge618
  },
  data() {
    return {
      navdata: [],
      ac_data: {}, //账户信息
      text: '',
      loginFormShow: false,
      placeholderText: '一颗鸡蛋里面包含哪些营养物质？',
      placeList: ['一颗鸡蛋里面包含哪些营养物质？', '单身很久的人怎么找到女朋友？', '如何看待996？', '为什么没有特别帅的帅哥来追我？', '下班后领导发信息该不该回复?', '如何和暧昧对象保持聊天？'],
      iconActive: false,
      timer: null,
      timer2: null,
      newRechargeShow: false, //新用户充值
      needRecharge: 0,

      his_list: [],
      newmemberTimer: null,
      newMemberRechargeShow: false,
      isend: true,
      shownav: true,

      bannerList: [],
      aiNewsList: [],
      ainewsIdx: undefined,
      ainewsVisible: false,

      luckyitem: undefined
    }
  },
  computed: {
    tokenVuex() {
      // 存储位置： store.state.{模块名,在store/index.js中module下的}.{state中定义的属性}
      return this.$store.state.user.token
    }
  },
  created() {
    if (this.getQueryVariable('cc')) {
      localStorage.setItem('cc', this.getQueryVariable('cc'))
    }
    if (this.getQueryVariable('bd_vid') || this.getQueryVariable('qhclickid')) {
      localStorage.setItem('bd_vid_url', window.location.href)
      // localStorage.setItem( "bd_vid_url",   'https://boserai.com?bd_vid=uANBIyIxUhNLgvw-I-tkP10vnH04g1cvg1DLnj0snWRvnWmdrHm'  )
    }
    if (this.getQueryVariable('qhclickid')) {
      localStorage.setItem('qhclickid', this.getQueryVariable('qhclickid'))
    }
  },
  mounted() {
    this.debounce(this.getUserAccountInfo(), 1000)
    this.navdata = this.$i18n.messages[this.$i18n.locale].getNavData
    if (this.$route.params && this.$route.params.unlogin === true) {
      // this.loginFormShow = true ;
    }
    this.img_page_get()
    // 获取ai运势
    qa_template_list_req(undefined, undefined, undefined).then(res => {
      res.data.forEach(element => {
        if (element.title == 'AI运势') {
          this.luckyitem = element
        }
      })
    })

    this.getBannerList()
    if (this.getQueryVariable('rec_id')) {
      this.get_rec_info(this.getQueryVariable('rec_id'))
    }

    if (this.getQueryVariable('share_url')) {
      painting_shareUrl({
        imgMd5: this.getQueryVariable('share_url')
      }).then(res => {
        this.gotoshare(res.data)
      })
    }

    let _index = Math.floor(Math.random() * this.placeList.length)
    this.placeholderText = this.placeList[_index]
    this.timer = setInterval(() => {
      let _index = Math.floor(Math.random() * this.placeList.length)
      this.placeholderText = this.placeList[_index]
    }, 5000)

    _czc.push(['_trackEvent', 'home', 'enter', localStorage.getItem('cc') ? localStorage.getItem('cc') : 'none', '', ''])
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    debounce(fn, wait) {
      var timeout = null
      return function () {
        if (timeout !== null) clearTimeout(timeout)
        timeout = setTimeout(fn, wait)
      }
    },
    countdownMed(tdata, acsuccess) {
      if (!acsuccess) return
      this.timer2 = null
      // console.log( new Date(  new Date().getTime() + tdata*1000) );
      if (tdata > 0) {
        this.isend = false
        this.$nextTick(() => {
          this.newMemberRechargeShow = true
        })
      } else {
        this.timer2 = setTimeout(() => {
          if (this.$route.name != 'home') return
          var todaysDate = new Date()
          if (localStorage.getItem('hasRecharge')) {
          } else {
            if (localStorage.getItem('rechargeShowDate')) {
              //如果有
              if (localStorage.getItem('rechargeShowDate') == todaysDate.setHours(0, 0, 0, 0)) {
                //弹过了
              } else {
                localStorage.setItem('rechargeShowDate', todaysDate.setHours(0, 0, 0, 0))
                this.newRechargeShow = true
              }
            } else {
              localStorage.setItem('rechargeShowDate', todaysDate.setHours(0, 0, 0, 0))
              this.newRechargeShow = true
            }
          }
        }, 1000)
      }
    },
    async getUserAccountInfo(obj) {
      const { data, success } = await user_account_req()
      const { data: tdata, success: tsuccess } = await user_countdown_req()
      // const { data : tdata , success: tsuccess } = await this.countdown_618();

      // console.log( new Date().getTime() )
      // console.log( new Date().getTime() + tdata*1000 );
      if (tdata > 0) {
        this.countdownMed(tdata, success)
        return
      } else {
        setTimeout(() => {
          if (this.$route.name != 'home') return
          this.isend = true
          this.newMemberRechargeShow = false
          var todaysDate = new Date()
          if (localStorage.getItem('hasRecharge')) {
          } else {
            if (localStorage.getItem('rechargeShowDate')) {
              //如果有
              if (localStorage.getItem('rechargeShowDate') == todaysDate.setHours(0, 0, 0, 0)) {
                //弹过了
              } else {
                localStorage.setItem('rechargeShowDate', todaysDate.setHours(0, 0, 0, 0))
                this.newRechargeShow = true
              }
            } else {
              localStorage.setItem('rechargeShowDate', todaysDate.setHours(0, 0, 0, 0))
              this.newRechargeShow = true
            }
          }
        }, 1000)
      }

      if (success) {
        if (data.ext) {
          data.ext = JSON.parse(data.ext)
          this.adultSwitch = data.ext && data.ext.adultSwitch ? data.ext.adultSwitch : false
        }
        this.ac_data = data

        if (obj && obj.page) {
          this.event_active_log(obj.page)
        }
      }
    },
    countdown_618() {
      var currentTime = new Date().getTime()
      // 设置目标时间为 2024 年 6 月 20 日 23:59:59 的时间戳（单位：毫秒）
      var targetDate = new Date('2024-06-20T23:59:59Z').getTime() - 8 * 60 * 60 * 1000
      // 计算剩余秒数（单位：秒）
      var remainingSeconds = Math.floor((targetDate - currentTime) / 1000)
      // console.log("从现在到 2024 年 6 月 20 日晚上剩余的秒数为：" + remainingSeconds + " 秒");
      let data = remainingSeconds
      return new Promise(resolve => {
        resolve({
          data: data
        })
      })
    },
    event_active_log(page) {
      let cnl = localStorage.getItem('cc') ? localStorage.getItem('cc') + '-pc' : 'none-pc'
      let uid = this.ac_data.uid ? this.ac_data.uid : getUUID()
      var ts = Date.parse(new Date())
      let detail_str = JSON.stringify({ page: page })
      let sign = md5('cnl' + cnl + 'detail' + detail_str + 'event' + 'active' + 'ts' + ts + 'uid' + uid + 'yEBgmO69')
      event_log_req({
        // category : '' ,
        cnl: cnl,
        detail: detail_str,
        event: 'active',
        ts: ts,
        uid: uid,
        sign: sign
      })
    },
    search_click() {
      // this.$store.commit('user/change_token') ;

      // if( !this.tokenVuex ){
      //   this.loginFormShow = true ;
      //   return;
      // }

      if (this.text) {
        this.$router.push({
          name: 'aiQa', // ⚠️注：这里不能用path路径，只能用name【请对照router.js中的路由规则中的name项】，否则取不到传过去的数据
          params: {
            text: this.text ? this.text : this.placeholderText,
            place: this.text ? false : true
          }
        })
      } else {
        this.$router.push({
          name: 'aiQaScheme', // ⚠️注：这里不能用path路径，只能用name【请对照router.js中的路由规则中的name项】，否则取不到传过去的数据
          params: {
            text: this.text ? this.text : this.placeholderText,
            place: this.text ? false : true
          }
        })
      }
    },
    img_click() {
      // this.$store.commit('user/change_token') ;

      // if( !this.tokenVuex ){
      //   this.loginFormShow = true ;
      //   return;
      // }
      this.$router.push({
        name: 'textImage' // ⚠️注：这里不能用path路径，只能用name【请对照router.js中的路由规则中的name项】，否则取不到传过去的数据
      })
    },
    text_to_img_click() {
      // this.$store.commit('user/change_token') ;
      // if( !this.tokenVuex ){
      //   this.loginFormShow = true ;
      //   return;
      // }
      this.$router.push({
        name: 'textImage' // ⚠️注：这里不能用path路径，只能用name【请对照router.js中的路由规则中的name项】，否则取不到传过去的数据
      })
    },
    img_to_img_click() {
      // this.$store.commit('user/change_token') ;
      // if( !this.tokenVuex ){
      //   this.loginFormShow = true ;
      //   return;
      // }
      this.$router.push({
        name: 'imageToImage' // ⚠️注：这里不能用path路径，只能用name【请对照router.js中的路由规则中的name项】，否则取不到传过去的数据
      })
    },
    ai_changeface_click() {
      this.$router.push({
        name: 'ReplaceFace' // ⚠️注：这里不能用path路径，只能用name【请对照router.js中的路由规则中的name项】，否则取不到传过去的数据
      })
    },
    goto_ailucky() {
      if (this.luckyitem) {
        this.gotoQaByType(this.luckyitem)
      }
    },
    loginFormShowChange(data) {
      this.loginFormShow = data
    },
    getQueryVariable(variable) {
      var query = window.location.search.substring(1)
      var vars = query.split('&')
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=')
        if (pair[0] == variable) {
          return pair[1]
        }
      }
      return false
    },
    get_rec_info(id) {
      square_detail(id).then(res => {
        let obj = JSON.parse(res.data.request)
        if (obj) {
          obj._id = id
          obj.category = res.data.category
          obj.url = res.data.url
          obj.createdAt = res.data.createdAt
          obj.router = 'explore'
          this.$router.replace({
            name: 'test', // ⚠️注：这里不能用path路径，只能用name【请对照router.js中的路由规则中的name项】，否则取不到传过去的数据
            params: obj
          })
        }
      })
    },
    gotoshare(url) {
      let obj = {}
      obj.router = 'share'
      obj.url = url
      this.$router.replace({
        name: 'test', // ⚠️注：这里不能用path路径，只能用name【请对照router.js中的路由规则中的name项】，否则取不到传过去的数据
        params: obj
      })
    },
    beianClick() {
      window.location.href = 'https://beian.miit.gov.cn/'
    },
    gotoRecharge() {
      if (getToken()) {
        this.newRechargeShow = false
        this.needRecharge++
      } else {
        this.loginFormShow = true
      }
    },
    // 获取历史记录
    img_page_get(val, searchvalue) {
      this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 1)',
        customClass: 'my_loading'
      })

      let _this = this
      let type = undefined
      let desc = undefined
      qa_template_list_req(type, desc, 6).then(res => {
        this.his_list = res.data
        _this.$loading().close()
      })
    },
    gotoQaByType(item) {
      // this.$store.commit('user/change_token') ;
      // if( !this.tokenVuex ){
      //   this.loginFormShow = true ;
      //   return;
      // }

      // item.router = 'aiQaSchemeqa';
      // this.$router.push({
      //   name: "test", // ⚠️注：这里不能用path路径，只能用name【请对照router.js中的路由规则中的name项】，否则取不到传过去的数据
      //   params: item
      // });
      this.$router.push({
        // name : "aiQaSchemeqa", // ⚠️注：这里不能用path路径，只能用name【请对照router.js中的路由规则中的name项】，否则取不到传过去的数据
        path: '/ai-qa-schemeqa',
        query: {
          params: JSON.stringify(item)
        }
      })
    },
    isToday(date) {
      //今天
      var d = new Date(date.toString().replace(/-/g, '/'))
      var todaysDate = new Date()
      if (d.setHours(0, 0, 0, 0) == todaysDate.setHours(0, 0, 0, 0)) {
        return true
      } else {
        return false
      }
    },
    // 获取新用户充值
    usercountdown() {
      user_countdown_req().then(res => {
        // console.log( res )
        // console.log( new Date()  )
      })
    },
    getBannerList() {
      banner_list_req({
        type: 1,
        cnl: localStorage.getItem('cc') ? localStorage.getItem('cc') + '-pc' : 'none-pc'
      }).then(res => {
        this.bannerList = res.data
      })
    },
    bannerClick(item) {
      let activeIndex = this.$refs.carousel.activeIndex
      window.location.href = this.bannerList[activeIndex].redirectUrl
    },
    getAiNews() {
      this.$axios.get('https://innews.infohubnews.app/v1/channels/news?cty=CN&lang=zh&offset=0&limit=100&ch=telegram&c=finance').then(res => {
        if (res.data.Result) {
          this.aiNewsList = res.data.Result
        }
      })
    },
    ainewsclick(item, idx) {
      // window.open( item.link ,  "_blank" )  ;
      this.ainewsIdx = idx
      this.ainewsVisible = true
    },
    changeaiidx(idx) {
      this.ainewsIdx = idx
    },
    openAinews(idx) {
      this.ainewsVisible = false
      this.$nextTick(() => {
        setTimeout(() => {
          this.ainewsIdx = idx
          this.ainewsVisible = true
        }, 30)
      })
    }
  },
  watch: {
    tokenVuex(newQuestion, oldQuestion) {
      if (!newQuestion) {
        this.getBannerList()

        //this.loginFormShow = true ;
      } else {
        let page = undefined
        if (this.$route.name == 'home') {
          page = 'home'
        } else if (this.$route.name == 'aiQa') {
          page = 'qa'
        } else if (this.$route.name == 'textImage') {
          page = 'textimage'
        } else if (this.$route.name == 'imageToImage') {
          page = 'imageimage'
        } else if (this.$route.name == 'explore') {
          page = 'explore'
        } else if (this.$route.name == 'Personal') {
          page = 'personal'
        }

        this.debounce(
          this.getUserAccountInfo({
            page: page
          }),
          1000
        )
        this.getBannerList()
      }
    }
  },
  activated() {
    this.shownav = false
    this.$nextTick(() => {
      this.shownav = true
    })
    this.getAiNews()
  },
  timeStampToHumanReadableMtd(time) {
    return timeStampToHumanReadable(time)
  }
}
</script>

<style lang="scss" scoped>
::v-deep .form-control {
  border: none;
}
::v-deep .form-control:focus {
  border-color: #fff;
  box-shadow: none;
}
::v-deep .el-carousel__indicator.is-active button {
  background-color: #006fff;
}
::v-deep .el-carousel__indicator button {
  background-color: rgba(255, 255, 255, 0.2);
}

.cont {
  padding: 60px 20px 0 0;
  // height: calc(100% );
  box-sizing: border-box;
  .gongxin {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 132px;
    height: 23px;
    background: #222337;
    opacity: 0.5;
    color: #fff;
    text-align: center;
    line-height: 23px;
    font-size: 11px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    z-index: 9999;
    cursor: pointer;
  }
}
.row {
  margin-left: 0;
  margin-right: 0;
}

// .icon {
//   width: 40%;
//   min-width: 70px;
//   min-height: 70px;
//   padding-bottom: 40%;
//   margin: 0 auto;
// }

.home1 {
  margin-top: 77px;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  color: #fff;
}

.home2 {
  margin-top: 42px;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;

  .item {
    box-sizing: border-box;
    height: 40px;
    width: 96px;
    border-radius: 20px;
    text-align: center;
    line-height: 40px;
  }
  .active {
    background-color: #fff;
    color: #fff;
    font-weight: 500;
    height: 40px;
    width: 96px;
    background: #222337;
    border-radius: 20px;
    text-align: center;
    line-height: 40px;
    margin-right: 32px;
  }
}
::v-deep .home3 .el-input__inner {
  background-color: #222337;
  border: none;
  color: #fff;
  padding-left: 0;
}
::v-deep .home3 .el-input__inner::placeholder {
  color: #49495a;
}

.home3 {
  width: 1000px;
  justify-content: center;
  align-items: center;
  color: #fff;
  height: 66px;
  border-radius: 33px;
  background-color: #222337;
  margin: 24px auto 20px;
  .l {
    justify-content: center;
    align-items: center;
    width: 72px;
    height: 66px;
  }
  .input {
    background-color: #222337;
    color: #fff;
    padding: 0;
  }
  .input::placeholder {
    color: #49495a;
  }

  .search-btn {
    height: 66px;
    width: 92px;
    align-items: center;
    justify-content: center;
  }
}

.waterfall_title {
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 33px;
  margin-top: 43px;
  margin-bottom: 16px;
}

.hotApply {
  ._top {
    margin-top: 40px;
    display: flex;
    align-items: center;
    line-height: 30px;
    height: 30px;
    font-size: 22px;
    font-weight: 500;
    color: #ffffff;
    display: flex;
  }
  .bot {
    margin-top: 18px;
    display: flex;
    justify-content: space-between;
    .item {
      width: calc(100% / 6 - 14px);
      height: 144px;
      background-color: #222337;
      border-radius: 10px;
      position: relative;
      padding: 15px 15px 0 15px;
      .top {
        height: 36px;
        display: flex;
        align-items: center;
        overflow: hidden; //超出的文本隐藏
        text-overflow: ellipsis; //溢出用省略号显示
        white-space: nowrap; //溢出不换行
        .icon {
          height: 36px;
          width: 36px;
          font-size: 17px;
          font-weight: 500;
          color: #ffffff;
          margin-right: 7px;
          border-radius: 50%;
          flex-shrink: 0;
        }
      }
      .desc {
        margin-top: 7px;
        font-size: 13px;
        color: #9a9ab7;
        line-height: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box; //作为弹性伸缩盒子模型显示。
        -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
        -webkit-line-clamp: 2; //显示的行
      }
      .bottom {
        bottom: 0;
        left: 0;
        right: 0;
        position: absolute;
        height: 36px;
        line-height: 36px;
        font-size: 14px;
        color: #9a9ab7;
        text-indent: 15px;
        background: #2b2c43;
        border-radius: 0 0 10px 10px;
      }
    }
  }

  .aireplaceitem {
    background-image: url('../assets/img/replaceface/bg3.png');
    background-position: center center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;
    .botbtn {
      position: absolute;
      bottom: 14px;
      left: 14px;
      width: 66px;
      height: 24px;
      line-height: 24px;
      background: linear-gradient(315deg, #006fff 0%, #72e3f5 100%);
      border-radius: 12px;
      text-align: center;
      font-weight: 500;
      font-size: 12px;
      color: #ffffff;
    }
  }
}

.top_cont {
  width: 100%;
  height: 312px;
  display: flex;
  .banner {
    //background-image:  url("../assets/img/home/banner.png") ;
    background-position: center center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;
  }
  .news {
    flex: 1;
    height: 100%;
    background: linear-gradient(180deg, #4d5063 0%, #0c112a 100%);
    border-radius: 10px;
    overflow-y: scroll;
    padding: 12px;
    box-sizing: border-box;
  }
  .title {
    font-weight: 600;
    font-size: 20px;
    color: #ffffff;
    margin-bottom: 15px;
  }
  .item {
    position: relative;
    width: 100%;
    margin-bottom: 15px;
    cursor: pointer;
    .left {
      position: absolute;
      width: 10px;
      height: 10px;
      background: #72e3f5;
      border-radius: 50%;
      left: 0;
      top: 6px;
    }
    .right {
      padding-left: 18px;
    }
    font-weight: 400;
    font-size: 15px;
    color: #9a9ab7;
  }
  .citem1 {
    width: 49%;
    height: calc(100% - 16px);
    flex-shrink: 0;
    background-image: url('../assets/img/home/bg1.png');
    background-position: top 0% left 0%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .citem2 {
    width: 49%;
    height: calc(100% - 16px);
    flex-shrink: 0;
    background-image: url('../assets/img/home/bg2.png');
    background-position: top 0% left 0%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .citem3 {
    width: 49%;
    height: calc(100% - 16px);
    flex-shrink: 0;
    background-image: url('../assets/img/home/bg8.png');
    background-position: top 0% left 0%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
</style>
