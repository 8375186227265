import request from '@/utils/request'
import axios from 'axios'

export function growth_tasks_req(query) {
  return request({
    url:  '/growth/tasks',
    method: 'get',
    params: query
  })
}

export function growth_publish_req(query) {
  return request({
    url:  '/growth/publish',
    method: 'get',
    params: query
  })
}

export function growth_level_req(query) {
  return request({
    url:  '/growth/level',
    method: 'get',
    params: query
  })
}
 

