import Vue from 'vue'
import VueI18n from 'vue-i18n'

// 引入自定义中文包
import customZH from './locale/zh'

// 引入自定义英文包
import customEN from './locale/en'


Vue.use(VueI18n)

// 准备翻译的语言环境信息
const messages = {
  en: {
    message: {
      homeTitle : "BS AI" ,
      hello: 'hello world' ,
      homeTitleLeft : "主页" ,
    },
    ...customEN // 将自定义英文包加入
  },
  zh: {
    message: {
      homeTitle : "BS AI小工具集合" ,
      hello: '您好世界！' ,
      homeTitleLeft : "主页" ,
    },
    ...customZH // 将自定义英文包加入
  },  
}


function getQueryVariable(variable) {
  const query = window.location.search.substring(1);
  const vars = query.split("&");
  for (let i=0; i < vars.length; i++) {
    let pair = vars[i].split("=");
    if(pair[0] == variable){return pair[1];}
  }
  return(false);
}


function getlanguage(){
  if( getQueryVariable("lang") ){ 
    if( getQueryVariable("lang")  == "zh" ){
      return "zh"
    } else if(  getQueryVariable("lang")  == "en"  ){
      return "en"
    } else {
      return "en"
    }
  } else if(  navigator.language ){
    if( navigator.language.toLowerCase().indexOf("zh") != -1 ){
      return "zh"
    } else {
      return "en"
    }
  } else {
    return "en"
  }
}



// 通过选项创建 VueI18n 实例
let locale = getlanguage()
const i18n = new VueI18n({
  locale: locale  , // 设置地区
  messages // 设置地区信息
})

export default i18n

