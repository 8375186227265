<template>
  <el-dialog class="dialog top_cont" :visible.sync="ainewsVisible" :before-close="handleClose" :show-close="false"
    append-to-body width="864px" ref="dialog" >
    <div class="cont">
      <div class="title d-flex">
        <div>
          {{ aiNewsList[ainewsIdx].title }}
        </div>

        <el-image style="width: 24px; height: 24px;cursor: pointer;position: absolute;right: -20px;top: -6px;"
          @click="handleClose()" :src="require('../assets/img/recharge/close.png')" fit="contain">
          <div slot="placeholder" class="image-slot" style="background-color: rgb(17, 18, 37);"></div>
        </el-image>
      </div>
      <div class="date">
        发布时间：{{ timeStampToHumanReadableMtd( aiNewsList[ainewsIdx].updated * 1000  ) }}
      </div>
      <div>
        <div class="sub_title">
          {{ aiNewsList[ainewsIdx].content }}
        </div>
      </div>
      <div>
        <el-button type="text" style="color: #006FFF;margin-bottom: 32px;" @click="ainewsclickOpen">查看原文</el-button>
      </div>
      <div class="rec" style="display: flex;align-items: center;font-weight: 500;font-size: 20px;color: #FFFFFF;margin-bottom: 19px;">
        <el-image style="width: 20px; height: 20px;cursor: pointer;margin-right: 5px;"
          :src="require('../assets/img/ainews/rec.png')" fit="contain">
          <div slot="placeholder" class="image-slot"></div>
        </el-image>
        为你推荐
      </div>


      <!-- <div style="height: 300px;overflow-y: scroll;width: 100%">
        <div v-for="item in 100">
          111111
        </div>
      </div> -->

      <div class="list">
        <div class="item" v-for="(item,idx) in aiNewsList" :key="idx" @click="ainewsclick(item, idx)">
          <div class="left"></div>
          <div class="right">
            {{item.title}}
          </div>
        </div>
      </div>
      <div style="height: 20px;">

      </div>
    </div>
    
  </el-dialog>
</template>

<script>
import { getBaseUrl, getToken , timeStampToHumanReadable  } from "@/utils/bose";
import { guestbookSaveReq , guestbook_page_req } from "@/api/feedback"



export default {
  props: {
    ainewsVisible: {
      type: Boolean,
      default: false,
    },
    aiNewsList : {
      type: Array,
      default: [] ,
    },
    ainewsIdx : {
      type: Number ,
      default: 0,
    }
  },
  data() {
    return {
      type: "1",
      msg: undefined,
      picsList: [undefined, undefined, undefined, undefined, undefined],
      baseUrl: getBaseUrl(),
      myHeaders: {
        'x-token': getToken(),
      },
      tel: undefined,


      feedTypeData : [{
        status : 2 , 
        name : "全部状态" ,
        flag : true , 
      } ,{
        status : 0 , 
        name : "跟进中" ,
        flag : false  , 
      },{
        status : 1 , 
        name : "已完结" ,
        flag : false  , 
      }] ,
      replyList : [] ,
      pageNum : 1 , 
      pageSize : 10 , 
      replystatus : 2 , 
      replyFinished : false , 
      replyLoading : false , 
    }
  },
  methods: {
    handleClose(done) {
      this.$emit('update:ainewsVisible', false) // 传递更新数值
    },
    beforePicUpload(file) {
      const isJPG = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp'].indexOf(file.type) > -1 ? true : false;
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$toast.open({
          message: "请检查上传图片的格式",
          position: 'top',
          duration: 3000,
          type: 'error',
        });
        return false;
      }
      if (!isLt2M) {
        this.$toast.open({
          message: "上传图片大小不能超过 2MB!",
          position: 'top',
          duration: 3000,
          type: 'error',
        });
        return false;
      }
      return isJPG && isLt2M;
    },
    uploadSuccess(response, file, fileList, idx) {
      this.$set(this.picsList, idx, response.data)
    },
    deleteClick(idx) {
      this.$set(this.picsList, idx, undefined)
    },
    submit() {
      if( !this.msg ){
        this.$toast.open({
          message:  "请输入留言哦" ,
          position: 'top',
          duration : 3000,
          type: 'error',
        });
        return ;
      }
      let pics = [];
      this.picsList.forEach(ele => {
        if (ele) {
          pics.push(ele)
        }
      })
      guestbookSaveReq({
        tel: this.tel,
        msg: this.msg,
        pics: pics,
        type: this.type,
      }).then(res => {
        this.isSuccess = true;
      }).catch(e => {
        this.isFail = true;
      })

    },
    toggleList(){
      this.curPage = 2 ;
      this.pageNum = 1 ;
      this.replyList = [] ;
      this.replyFinished = false 
      // this.guestbookPage(); 
    },
    goBack(){
      this.curPage = 1 ;
      this.pageNum = 1 ;
      this.replyList = [] ;
      this.replyFinished = false 
    },
    clickType( idx ){
      this.feedTypeData.forEach( val => {
        val.flag = false  ;
      })
      this.feedTypeData[idx].flag = true ;
      this.replystatus = this.feedTypeData[idx].status ;
      this.pageNum = 1 ;
      this.replyList = [] ;
      this.replyFinished = false 
      this.replyload() ;
    },
    guestbookPage(){
      guestbook_page_req({
        pageNum : this.pageNum , 
        pageSize : this.pageSize , 
        status : this.replystatus , 
      }).then( res => {
        this.replyLoading = false  ;
        this.replyList = this.replyList.concat( res.data.records ) ;
        this.pageNum++;
        if( res.data.current >= res.data.pages ){
          this.replyFinished = true ;
        }
      })
    },
    replyload () {
      // this.pageNum++ ;
      if( this.replyFinished || this.replyLoading ) return ;
      this.replyLoading  = true ;
      this.guestbookPage();

    },
    ainewsclick( item , idx){
      this.$emit( 'openAinews' , idx  );

      // this.$emit('update:ainewsVisible', false) // 传递更新数值


    },
    ainewsclickOpen(){
      window.open( this.aiNewsList[ this.ainewsIdx ].link ,  "_blank" )  ;
    },
    timeStampToHumanReadableMtd( time ){
      return timeStampToHumanReadable( time );
    }
  }
}
</script>

<style lang="scss" scoped>  
::v-deep .el-dialog {
    min-width: 864px;
    // position: relative;
    // background-color: palegreen;
    background-color: #1B1D2E !important;
    box-shadow: none;

    display: flex;
    margin: 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /*height:600px;*/
    max-height: calc(100% - 30px);
    max-width: calc(100% - 30px);
    border-radius: 20px;
    overflow-y: auto;
    overflow-x: hidden;
    .el-dialog__header {
      display: none;
    }

    .el-dialog__body {
      padding: 0;

      .cont {
        width: 864px;
        height: 623px;
        padding: 20px 34px;
        box-sizing: border-box;

        .title {
          min-height: 30px;
          line-height: 30px;
          width: 100%;
          position: relative;
          justify-content: center;
          align-items: center;
          font-size: 21px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 29px;
          text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
          justify-content: flex-start;
        }
        .date  {
          height: 17px;
          font-size: 12px;
          color: #9A9AB7;
          line-height: 17px;
          text-align: left;
          margin-top: 7px;
          margin-bottom: 18px;
        }

        .sub_title {
          width: 100%;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          color: #CACAD4;
          line-height: 21px;
          margin-top: 9px;
          margin-bottom: 14px;
          text-align: left;
          line-height: 22px;
        }

        .subject {
          margin-bottom: 13px;
          align-items: center;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
        }

        .type {
          justify-content: space-between;
          margin-bottom: 22px;

          .type_item {
            width: 190px;
            height: 40px;
            line-height: 38px;
            background: rgba(155, 184, 220, 0.08);
            border-radius: 10px;
            text-align: center;
            font-size: 15px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #CACAD4;
            cursor: pointer;
            border: 1px solid rgba(155, 184, 220, 0.08);
            box-sizing: border-box;
          }

          .type_item.active {
            background: rgba(0, 111, 255, 0.15);
            border: 1px solid #006FFF;
            color: #006FFF;
          }
        }

        .text-cont {
          box-sizing: border-box;
          border: none;
          max-height: 124px;
          padding: 8px 0;
          min-height: 66px;
          background: #111225;
          border-radius: 10px;

          textarea {
            border: none;
            resize: none;
            cursor: pointer;
          }
        }

        .upload_item {
          width: 70px;
          height: 70px;
          border-radius: 10px;

        }
      }
    }
  }




  ::v-deep .el-textarea.is-disabled .el-textarea__inner {
    background: #111225;
  }

  ::v-deep .el-textarea__inner {
    background: #111225;
    border: none;
    color: #fff;

    &::placeholder {
      color: #666;
    }

    &::-webkit-input-placeholder {
      /* WebKit browsers 适配谷歌 */
      color: #666;
    }

    &:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 适配火狐 */
      color: #666;
    }

    &::-moz-placeholder {
      /* Mozilla Firefox 19+ 适配火狐 */
      color: #666;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10+  适配ie*/
      color: #666;
    }
  }




  ::v-deep .avatar-uploader .el-upload {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 70px;
    height: 70px;
    border-radius: 10px;
    background: rgba(155, 184, 220, 0.08);
    border-radius: 10px;
    color: #CACAD4;
    margin-right: 12px;
  }

 .replyListCont {
  .replyItem {
    padding: 12px 16px;
    box-sizing: border-box ;
    background: #111225;
    margin-bottom: 20px;
    .date {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #9696A2;
      line-height: 20px;
    }
    .line {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #CACAD4;
      line-height: 20px;
    }
    .answer {
      background: #1B1D2E;
      border-radius: 10px;
      padding: 10px 14px;
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #9696A2;
      line-height: 18px;
      // background: #111225;
      margin-top: 16px;
      background: #1B1D2E;

    }
    .icon {
      background: #F5B109
    }
    .activedIcon {
      background : #12D107
    }
  }
 } 



 .top_cont {
    width: 100%;
    height: 100%;
    min-height: calc(100% - 30px);
    max-height: calc(100% - 30px);
    // height: 312px;
    display: flex;
  .banner {
    //background-image:  url("../assets/img/home/banner.png") ;
    background-position: center center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;
  }
  .news {
    flex: 1 ;
    height: 100%;
    background: linear-gradient( 180deg, #4D5063 0%, #0C112A 100%);
    border-radius: 10px;
    overflow-y: scroll;
    padding: 12px;
    box-sizing: border-box;
  }
  .title {
    font-weight: 600;font-size: 20px;color: #FFFFFF;margin-bottom: 15px;
  }
  .item {
    position: relative;
    width: 100%;
    margin-bottom: 15px;
    cursor: pointer;
    .left {
      position: absolute;
      width: 10px;
      height: 10px;
      background: #72E3F5;
      border-radius: 50%;
      left: 0;
      top: 6px;
    }
    .right {
      padding-left: 18px;
    }
    font-weight: 400;
    font-size: 15px;
    color: #9A9AB7;
  }
  .citem1 {
    width: 343px;
    height: 312px;
    flex-shrink:0;
    background-image:  url("../assets/img/home/bg1.png") ;
    background-position: top 0% left 0%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;
    margin-right: 16px;

    .l {
      position: absolute;
      left: 0;
      top: 0;
      width: 180px;
      height: 312px;
      background-image:  url("../assets/img/home/bg2.png") ;
      background-position: center center;
      background-size: 100% 100%;
    }
  }
  .citem2 {
    width: 313px;
    height: 312px;
    flex-shrink:0;
    // background-image:  url("../assets/img/home/bg3.png") ;
    background-position: center center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-right: 16px;
    .t {
      width: 100%;
      height: 148px;
      background-image:  url("../assets/img/home/bg6.png") ;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      margin-bottom: 16px;
    }
    .b {
      width:100% ;
      height: 148px  ;
      background-image:  url("../assets/img/home/bg7.png") ;
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>