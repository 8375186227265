import { msg_typeCount_req } from "@/api/message";

export default {
  namespaced: true,
    state : {
      // 这里放要管理的状态
      totalMessageNum : 0 ,
      officeNum : 0 ,
      zanNum : 0 , 
      guanzhuNum : 0 , 
      yingyongNum : 0 , 
    },
    mutations:{
      // 定义待发送的事件，第一个参数会注入当前的state
      changeTotalMessageNum( state  , num ){
        state.totalMessageNum = num  ;
      } ,
      changeOfficeNum( state , num  ){
        state.officeNum =  num 
      } ,
      changeZanNum( state , num  ){
        state.zanNum =  num 
      } ,
      changeGuanzhuNum( state , num  ){
        state.guanzhuNum =  num 
      } ,
      changeYingyongNum( state , num  ){
        state.yingyongNum =  num 
      } ,
    },
    actions: {
      changeMesNumAct({ commit }){
        msg_typeCount_req().then( res => {
          let totalNum = 0 ;
          res.data.records.forEach(ele => {
            totalNum += ele.unReadNum ;
            
            if( ele.type == 0 ){
              commit('changeOfficeNum' , ele.unReadNum ) 
            } else if( ele.type == 1 ){
              commit('changeZanNum' , ele.unReadNum ) 
            } else if( ele.type == 2 ){
              commit('changeYingyongNum' , ele.unReadNum ) 
            } else if( ele.type == 3 ){
              commit('changeGuanzhuNum' , ele.unReadNum ) 
            }          
          });
          commit('changeTotalMessageNum' , totalNum) 
        })
      }
    }
  }
  
  