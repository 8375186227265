import request from '@/utils/request'
import axios from 'axios'

// let api = process.env.VUE_APP_API_BASE_URL
let api = ""


export function te_op_req(data) {
  return request({
    url:  '/te/opt',
    method: 'post',
    data: data,
    timeout: 1000 * 60 // request timeout
  })
}

export function te_page_req(data) {
  return request({
    url:  '/te/page',
    method: 'post',
    data: data,
  })
}

export function te_del(data) {
  return request({
    url:  '/te/del?id=' + data,
    method: 'GET',
    // query: data,
  })
}

export function chat_page_req(data) {
  return request({
    url:  '/chat/page',
    method: 'post',
    data: data,
  })
}

export function chat_add_req(data) {
  return request({
    url:  '/chat/add',
    method: 'post',
    data: data,
  })
}


export function chat_del_req(data){
  return request({
    url:  '/chat/del?id=' + data,
    method: 'GET',
    // query: data,
  })
}

export function chat_sensitiveKeywords_req( data ){
  return request({
    url:  '/chat/sensitiveKeywords',
    method: 'post',
    data: data,
  })
}

export function chat_push_req(data) {
  return request({
    url:  '/chat/push',
    method: 'post',
    data: data,
  })
}

export function qa_template_list_req(type , desc , top ) {
  let url = '/template/list?'
  if( type ){
    url = url + 'type=' + type + '&'
  }
  if( desc ){
    url = url + 'desc=' + desc 
  }
  if(  top ){
    url = url + 'top=' + top 
  }

  return request({
    url:  url ,
    method: 'GET',
    // query: data,
    // + '&desc=' + desc
  })
}