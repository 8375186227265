<template>
  <el-dialog class="dialog" :visible.sync="newRechargeShow" :before-close="handleClose" :show-close="false">
    <!-- <div class="main">
      <div class="btn_cont">
        <div class="_btn _btn1" @click="handleClose">残忍离开</div>
        <div class="_btn _btn2" @click="gotoRecharge">前往抢购</div>
      </div>
    </div> -->
    <div class="close">
      <el-image @click="handleClose" :src="require('../assets/img/recharge/close2.png')" />
    </div>
    <div class="flashSale" @click="gotoRecharge"></div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    newRechargeShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  methods: {
    handleClose(done) {
      this.$emit('update:newRechargeShow', false) // 传递更新数值
    },
    gotoRecharge() {
      this.$emit('gotoRecharge')
    },
    async onSubmit() {}
  },
  computed: {},
  mounted() {}
}
</script>

<style lang="scss" scoped>
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #006fff;
  border-color: #006fff;
  border-radius: 50%;
}

::v-deep .el-checkbox__input .el-checkbox__inner {
  border-color: #fff;
  border-radius: 50%;
}

::v-deep .el-dialog {
  // 中秋活动需求注释
  // width: 690px;
  // height: 690px;
  width: 637px;
  height: 600px;
  position: relative;
  // background-color: palegreen;
  background-color: rgba(0, 0, 0, 0) !important;
  // background-image: url('../assets/img/recharge/newRecharge.png') !important;  中秋活动需求注释
  background: url('../assets/img/recharge/midAutumn.png') !important;
  background-size: 100%;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);
  .flashSale {
    width: 548px;
    height: 450px;
    background: transparent;
    position: absolute;
    bottom: 60px;
    left: 66px;
  }
  .close {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 58px;
    right: 78px;
    z-index: 99;
    cursor: pointer;
  }
}

::v-deep .el-dialog .el-dialog__body {
  flex: 1;
  overflow: auto;
  padding: 0;
}
::v-deep .el-dialog .el-dialog__header {
  padding: 0;
}

//::v-deep .el-dialog
// ::v-deep .el-dialog::-webkit-scrollbar-thumb {
//   background-color: #000;
// }

::v-deep .el-dialog .main {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99;
  // background-image:url("../../public/assets/img/login/bg1.png") !important ;
  padding: 40px;
  box-sizing: border-box;
  // background-color: rgba(0, 0, 0, 0.4);
  border-radius: 33px;
  // backdrop-filter: blur(8px);
  .btn_cont {
    position: absolute;
    left: 205px;
    bottom: 180px;
    width: 284px;
    height: 50px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    ._btn {
      width: 134px;
      height: 50px;
      line-height: 50px;
      border-radius: 30px;
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      color: #05254d;
      border-radius: 30px;
      cursor: pointer;
      &._btn1 {
        background: #cce2ff;
      }
      &._btn2 {
        background: linear-gradient(315deg, #006fff 0%, #72e3f5 100%);
      }
    }
  }

  .title {
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    margin-top: 26px;
    position: relative;
  }

  .line_out {
    position: relative;

    .icon {
      position: absolute;
      top: 15px;
      left: 18px;
    }
  }

  .line {
    width: 370px;
    height: 50px;
    background: rgba(34, 35, 55, 0);
    border-radius: 33px;
    box-sizing: border-box;
  }

  .el-input__inner {
    height: 50px;
    border-radius: 33px;
    border: 2px solid #7d7d9b;
    background: rgba(34, 35, 55, 0);
    padding: 0 120px 0 50px;
    color: #fff;
  }

  .el-input__inner:focus {
    border-color: #006fff;
  }

  .protocol {
    margin-top: 22px;
  }

  .code_error .el-input__inner {
    border-color: red;
    color: red;
  }
}
</style>
