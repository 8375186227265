export default {
namespaced: true,
  state : {
      // 这里放要管理的状态
      token : localStorage.getItem('x-token') ? localStorage.getItem('x-token') : null ,
      infochange : 1 ,
      accountSetting : false , 
      needresize : false , 
      h : undefined , 
      m : undefined , 
      s : undefined , 
      ms : undefined , 
      hasnewrecharge : false ,
      accountSettingsShow : false , 
  },
  mutations:{
      // // 定义待发送的事件，第一个参数会注入当前的state
      change_token(state){
        state.token = localStorage.getItem('x-token') ? localStorage.getItem('x-token') : null 
      },
      change_info(state){
        state.infochange += 1 ;
      } ,
      change_accountSetting(state , flag ){
        state.accountSetting = flag ;
      } ,
      change_size(state , flag ){
        state.needresize = flag ; 
      },
      changeM_S_MS( state , obj ){
        state.m = obj.m ;
        state.s = obj.s ;
        state.ms = obj.ms ;
        state.h = obj.h ;

      } , 
      change_hasnewrecharge( state ){
        state.hasnewrecharge = true ; 
      },

  }
}

