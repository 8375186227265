<template>

  <el-backtop :bottom="24" :right="34" class="_backtop" ref="backtop">
    <!-- <el-image style="width: 40px; height: 40px;"
    :src="require('../assets/img/nav/totop.png')"
    fit="contain">
      <div slot="placeholder" class="image-slot"></div>
    </el-image> -->
    <span class="backtop_img" 
        @mouseover="_onmouseOver" 
        @mouseleave="_onmouseLeave"
        
      style="width: 40px; height: 40px;border-radius: 50%;background-size: 100% 100%;"
      :style="{'background-image': `url( ${ iconActive ?   require('@/assets/img/nav/totop2.png') : require('@/assets/img/nav/totop.png')} )`}"
    ></span>
  </el-backtop>
</template>

<script>
export default {
  data(){
    return {
      timer : null , 
      iconActive : false ,
    }
  },
  methods : {
    _onmouseOver: function(){
      this.iconActive = true ;
    },
    _onmouseLeave: function () {
      this.iconActive = false ;
    },
    check(){
      if( !document.getElementsByClassName("_backtop")[0] ){
        this.iconActive = false ;
      }
  
    }
  } ,
  beforeDestroy(){
    this.iconActive = false ;
  },
  mounted(){
    this.timer = setInterval(this.check, 500);
  }, 
  beforeDestroy(){
    clearInterval(this.timer);
  }
}
</script>
