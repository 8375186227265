<template>
  <el-dialog
    class="dialog"
    :visible.sync="dialogRecordVisible"
    :before-close="handleClose"
    :show-close="false"
    append-to-body
    width="864px"
  >
    <div class="cont d-flex">
      <div class="l" style="background: #111225;position: relative;">
        <div class="d-flex" style="justify-content: center;align-items: center;margin-bottom: 24px;">
          <el-image style="width: 36px; height: 36px;margin-right: 8px;cursor: pointer;" 
              :src="require('../assets/img/logo.png')"
              fit="contain" >
            <div slot="placeholder" class="image-slot"></div>
          </el-image>
          <span style="cursor: pointer;font-size: 16px;font-family: PingFangSC-Medium, PingFang SC;font-weight: 500;color: #FFFFFF;">Boser AI</span>
        </div>
        
        <div class="d-flex tab_item" :class="{ active: dialogRecordType === '1' }" @click="recordClick('1')">
          <el-image style="width: 20px; height: 20px;margin-right: 5px;cursor: pointer;" 
              :src="require('../assets/img/record/xiaofei.png')"
              fit="contain" >
            <div slot="placeholder" class="image-slot"></div>
          </el-image>
          积分记录
        </div>

        <div class="d-flex tab_item" :class="{ active: dialogRecordType === '2' }" @click="recordClick('2')">
          <el-image style="width: 20px; height: 20px;margin-right: 5px;cursor: pointer;" 
              :src="require('../assets/img/record/chongzhi.png')"
              fit="contain" >
            <div slot="placeholder" class="image-slot"></div>
          </el-image>
          充值记录
        </div>

        <div class="d-flex tab_item"  style="font-size: 14px;font-weight: 500;color: #FFFFFF;position: absolute;bottom: 22px;width: 142px;" @click="feedbackClick">
          <el-image style="width: 28px; height: 28px;margin-right: 6px;cursor: pointer;" 
              :src="require('../assets/img/record/kefu.png')"
              fit="contain" >
            <div slot="placeholder" class="image-slot"></div>
          </el-image>
          意见反馈
        </div>

      </div>
      <div class="r" >
        <div class="title d-flex" style="margin-bottom: 5px;">
          <div class="title_l" style="padding-left: 12px;">   
            <template v-if="dialogRecordType === '1'">积分记录</template>   
            <template v-if="dialogRecordType === '2'">充值记录</template>   
          </div>
          <div class="title_r">
            <el-image style="width: 24px; height: 24px;cursor: pointer;"
              @click="handleClose()"
              :src="require('../assets/img/recharge/close.png')"
              fit="contain" >
              <div slot="placeholder" class="image-slot" style="background-color: rgb(17, 18, 37);"></div>
            </el-image>
          </div>
        </div>

        <el-table
          :data="data1"
          style="width: 100%;margin-top:8px"
          class="_table"
          size="mini"
          row-class-name="table_row"
          v-if="dialogRecordType === '1'  &&  data1.length > 0"
          >
          <el-table-column
            label="消费场景"
            width="180"
            align="center"
            >
            <template slot-scope="scope">
              <span style="margin-left: 10px">
                {{  scope.row.category === 0 ? "文生图" : ""  }}
                {{  scope.row.category === 1 ? "图生图" : ""  }}
                {{  scope.row.category === 2 ? "问答" : ""  }}
                {{  scope.row.category === 3 ? "下载" : ""  }}
                {{  scope.row.category === 4 ? "AI换脸" : ""  }}
                {{  scope.row.category === 5 ? "AI写真" : ""  }}
                {{  scope.row.category === 6 ? "高清放大" : ""  }}
                {{  scope.row.category === 7 ? "高清修复" : ""  }}
                {{  scope.row.category === 8 ? "AI滤镜" : ""  }}
                {{  scope.row.category === 9 ? "一键美妆" : ""  }}

              </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="points"
            label="消费积分"
            width="180"
            align="center">
          </el-table-column>
          <el-table-column
            prop="createdAt"
            label="消费时间"
            align="center">
          </el-table-column>
        </el-table>
        <div v-else-if="dialogRecordType === '1'  &&  data1.length == 0" style="text-align: center;">
          <el-image style="width: 200px; height: 200px;cursor: pointer;margin: 0 auto;margin-top: 48px;"
            @click="handleClose()"
            :src="require('../assets/img/record/result.png')"
            fit="contain" >
            <div slot="placeholder" class="image-slot" style="background-color: rgb(17, 18, 37);"></div>
          </el-image>
          <div style="font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;color: #999999;margin-top: -16px;">暂无记录</div>
        </div>
        
        <div style="padding-left: 12px;margin-bottom: 14px;" class="d-flex" v-if="dialogRecordType === '2'">
          <div class="tab_item" :class="{ active: chongzhitype === '0' }" @click="chongzhiToggle('0')">全部</div>
          <div class="tab_item" :class="{ active: chongzhitype === '1' }" @click="chongzhiToggle('1')">会员充值</div>
          <div class="tab_item" :class="{ active: chongzhitype === '2' }" @click="chongzhiToggle('2')">积分充值</div>
        </div>

        <el-table
          :data="data2"
          style="width: 100%"
          class="_table"
          size="mini"
          row-class-name="table_row"
          v-if="dialogRecordType === '2' &&  data2.length > 0"
          >
          <el-table-column
            label="充值方式"
            width="120"
            align="center"
            >
            <template slot-scope="scope">
              <span> {{  scope.row.category == 2 ? "积分充值" : "会员充值" }} </span>
            </template>
          </el-table-column>
          <el-table-column
            label="充值金额"
            width="130"
            align="center">
            <template slot-scope="scope">
              <span> {{  (scope.row.amount / 100).toFixed(2) + "元" }} </span>
            </template>
          </el-table-column>
          <el-table-column
            label="充值时间"
            width="200"
            align="center">
            <template slot-scope="scope">
              <span> {{  scope.row.rechargeAt  }} </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="rechargeNo"
            label="订单号"
            align="center">
          </el-table-column>
        </el-table>
        <div v-else-if="dialogRecordType === '2'  &&  data2.length == 0" style="text-align: center;">
          <el-image style="width: 200px; height: 200px;cursor: pointer;margin: 0 auto;margin-top: 48px;"
            @click="handleClose()"
            :src="require('../assets/img/record/result.png')"
            fit="contain" >
            <div slot="placeholder" class="image-slot" style="background-color: rgb(17, 18, 37);"></div>
          </el-image>
          <div style="font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;color: #999999;margin-top: -16px;">暂无记录</div>
        </div>



        <div class="d-flex _pagination" style="justify-content: center;margin-top: 24px;">
          <el-pagination
            v-show="total > 0"
            small
            layout="prev , pager ,next"
            :total="total"
            :current-page.sync="pageNum"
            :page-size="pageSize"
            :pager-count="5"
            @current-change="paginationList"
            >
          </el-pagination>
        </div>


      </div>
    </div>
  </el-dialog>
</template>
<script>
import {  consumeRecordReq  ,  rechargeRecordReq  } from "@/api/record"

export default {
  props : {
    dialogRecordVisible: {
      type: Boolean ,
      default: false , 
    },
    dialogRecordType: {
      type: String ,
      default: "1" , 
    },
    dialogFeedbackVisible: {
      type: Boolean ,
      default: false , 
    },
  }, 
  data(){
    return {
      chongzhitype : "0" ,
      pageNum : 1, 
      pageSize : 10 , 
      total : 0 , 
      data1 : [] , 
      data2 : [] ,
    }
  },    
  methods: {
    handleClose(done) {
      this.$emit('update:dialogRecordVisible',  false ) // 传递更新数值
    } ,

    recordClick( type ){
      this.$emit('update:dialogRecordType',  type ) // 传递更新数值
    } , 
    feedbackClick(){
      this.$emit('update:dialogFeedbackVisible',  true ) // 传递更新数值
    },

    chongzhiToggle( type ){
      this.chongzhitype = type ;
    } ,
    getConsumeRecord(){
      consumeRecordReq({
        pageNum : this.pageNum , 
        pageSize : this.pageSize , 
      }).then( res => {
        this.total = res.data.total ;
        this.data1 = res.data.records ;
      })
    } ,
    getRechargeRecord(){
      rechargeRecordReq({
        pageNum : this.pageNum , 
        pageSize : this.pageSize , 
        type : this.chongzhitype , 
      }).then( res => {
        this.total = res.data.total ;
        this.data2 = res.data.records ;
      })
    },

    paginationList(){
      if(  this.dialogRecordType == "1"  ){
        this.getConsumeRecord()
      } else if( this.dialogRecordType == "2" ){
        this.getRechargeRecord()
      }
    },
    reset(){
      this.pageNum = 1 ;
      this.pageSize = 10 ; 
      this.total = 0 ; 
      this.data1 = [] ;
      this.data2 = [] ;
    },
  } ,
  mounted(){
    if(  this.dialogRecordType == "1"  ){
      this.getConsumeRecord()
    } else if( this.dialogRecordType == "2" ){
      this.getRechargeRecord()
    }
  },
  watch: {
    // 记录类型切换了
    dialogRecordType: function (newQuestion, oldQuestion) {
      this.reset();
      this.chongzhitype = "0";
      if(  this.dialogRecordType == "1"  ){
        this.getConsumeRecord()
      } else if( this.dialogRecordType == "2" ){
        this.getRechargeRecord()
      }
    },
    chongzhitype: function (newQuestion, oldQuestion) {
      this.reset();
      if(  this.dialogRecordType == "1"  ){
        this.getConsumeRecord()
      } else if( this.dialogRecordType == "2" ){
        this.getRechargeRecord()
      }
    },
  },



}
</script>

<style lang="scss" scoped>  


::v-deep .el-dialog {
min-width: 864px;
// position: relative;
// background-color: palegreen;
background-color:#1B1D2E  !important;
box-shadow : none ;

display: flex;
margin: 0 !important;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
/*height:600px;*/
max-height: calc(100% - 30px);
max-width: calc(100% - 30px);
border-radius: 20px ;

.el-dialog__header {
  display: none;
}
.el-dialog__body {
  padding: 0;    
  .cont {
    width: 864px;
    height: 594px;
    .l  {
      width: 166px;
      border-radius: 20px 0px 0px 20px;
      background-image:url("../assets/img/record/bg.png") !important;
      padding: 20px 12px;
      box-sizing: border-box;

      .tab_item {
        width: 100%;
        align-items: center;
        justify-content: center;
        height: 42px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #CACAD4;
        cursor: pointer;
        margin-bottom: 6px;
      }
      .tab_item.active{
        background: #1d2036;
        border-radius: 6px;
        opacity: 1;
        font-weight: 500;
        color: #FFFFFF;
      }
    }
    .r  {
      width: 698px;
      box-sizing: border-box;
      padding: 14px;
      .title {
        height: 24px;
        line-height: 24px;
        .title_l {
          text-align: left;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          flex: 1;
        }
        .title_r {
          text-align: right;
          flex: 1;
        }
      }

      .tab_item {
        height: 22px;
        background: #25293b;
        border-radius: 11px;
        line-height: 22px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #CACAD4;
        text-align: center;
        padding: 1px 9px;
        margin-right: 8px;
        cursor: pointer;
      }
      .tab_item.active {
        background: #006FFF;
        color: #fff;
      }

    }
  }
}
}


/*最外层透明*/
::v-deep .el-table,
::v-deep .el-table__expanded-cell {
 background-color: transparent !important;
}
/* 表格内背景颜色 */
::v-deep .el-table th,
::v-deep .el-table tr,
::v-deep .el-table td {
 background-color: transparent !important;
 border: 0; //去除表格
}
/*去除底边框*/
::v-deep.el-table td.el-table__cell {
 border: 0;
}
::v-deep.el-table th.el-table__cell.is-leaf {
 border: 0;
}
/*去除底部灰条.el-table::before*/
::v-deep .el-table::before {
  display: none;
}

::v-deep .el-table__header-wrapper .cell {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #999999;
}
::v-deep .el-table__body-wrapper .cell{
  color: #fff;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;}


/*设置xx条/页的框的颜色*/
::v-deep .el-select .el-input.is-focus .el-input__inner,
::v-deep .el-pagination__sizes .el-input .el-input__inner:hover,
::v-deep .el-select .el-input__inner:focus {
    border-color: #006FFF;
}
/*设置当前页码的样式，及鼠标移上其他页码时的样式,以及左右箭头鼠标移上的样式*/

::v-deep .el-pagination {
  background-color: transparent;
}
::v-deep .el-pager li.active,.el-pager li:hover,
::v-deep .el-pagination button:hover {
    color: #006FFF;
}

::v-deep .el-pagination{
  color: #fff;
}
::v-deep .el-pagination .el-pager li{
  background: transparent;
}
::v-deep .el-pagination button:disabled  , ::v-deep .el-pagination button {
  background: transparent;
}
::v-deep .el-pagination button  {
  color: #fff;
}
::v-deep .el-table--mini .el-table__cell {
  padding: 8px 0 ;
}



</style>