<template>
  <div>
    <div style="margin-bottom: 17px;font-size: 22px;font-weight: 500;color: #FFFFFF;margin-top: 34px;">
      AI画廊
    </div>

    <div class="waterfull d-flex" style="margin-bottom: 14px;margin-top: 17px;" v-if="route_name != 'explore'">

      <div style="flex: 1;" class="el-radio-group-cont">
        <el-radio-group v-model="type" size="mini"  @input="typeInput">
          <el-radio-button class="_laebl" label="1">最新</el-radio-button>
          <el-radio-button class="_laebl" label="2">最热</el-radio-button>
        </el-radio-group>
      </div>
      <div style="width: 106px;height: 32px;background: #222337;border-radius: 16px;justify-content: space-around;align-items: center;" class="d-flex">
        <el-image style="width: 20px; height: 20px;" v-if="columnType == 1"
        :src="require('../assets/img/waterfall/column1_a.png')"
          fit="contain">
          <div slot="placeholder" class="image-slot"></div>
        </el-image>
        <el-image style="width: 20px; height: 20px;" v-else
        :src="require('../assets/img/waterfall/column1.png')"
          fit="contain" @click="typeToggle( 1 )">
          <div slot="placeholder" class="image-slot"></div>
        </el-image>
        <el-image style="width: 20px; height: 20px;" v-if="columnType == 2"
        :src="require('../assets/img/waterfall/column2_a.png')"
          fit="contain">
          <div slot="placeholder" class="image-slot"></div>
        </el-image>
        <el-image style="width: 20px; height: 20px;" v-else
        :src="require('../assets/img/waterfall/column2.png')"
          fit="contain" @click="typeToggle( 2 )">
          <div slot="placeholder" class="image-slot"></div>
        </el-image>        

        <el-image style="width: 20px; height: 20px;" v-if="columnType == 3"
        :src="require('../assets/img/waterfall/column3_a.png')"
          fit="contain">
          <div slot="placeholder" class="image-slot"></div>
        </el-image>
        <el-image style="width: 20px; height: 20px;"  v-else
        :src="require('../assets/img/waterfall/column3.png')"
          fit="contain" @click="typeToggle( 3 )">
          <div slot="placeholder" class="image-slot"></div>
        </el-image>
      </div>
    </div>
    <waterfall :col='this.columnNum' :width="itemWidth"  :gutterWidth="14" :data="imgList"  :loadDistance="50"  @loadmore="loadwatermore"  v-if="show">
      <template>
        <div class="cell-item" v-for="(item,index) in imgList" :key="index"
        :class="{ hover: isHover && index == hoverIdx  }" v-on:mouseover="handleMouseOver(index)" v-on:mouseout="handleMouseOut(index)">
          <img  :src="item.url"  class="waterfall-item" style="width: 100%;" @click.stop="imgitem_click( item , index )"/> 
          <div class="btn" @click.stop="apply_click( item )" v-if="index>0">应用</div>
          <div class="botton_cont d-flex">
            <b-img style="width: 30px; height: 30px;border-radius: 50%;margin-right: 6px;" 
              @click.stop="gotoPersonal(item , index )"
              v-if="item.ownerPhoto"
              :src="item.ownerPhoto"
                fit="contain">
              <div slot="placeholder" class="image-slot"></div>
            </b-img>
            <b-img 
              v-else-if="item.url  == 'https://boserai.com/replaceface.gif'"
              style="width: 30px; height: 30px;margin-right: 6px;"
              :src="require('../assets/img/replaceface/replaceface.png')" fit="contain">
              <div slot="placeholder" class="image-slot"></div>
            </b-img>
            <b-img style="width: 30px; height: 30px;margin-right: 6px;" 
              @click.stop="gotoPersonal(item , index )"
              v-else
              :src="require('../assets/img/ava.png')"
                fit="contain">
              <div slot="placeholder" class="image-slot"></div>
            </b-img>
            <div style="flex:1;position: relative;box-sizing: border-box;align-items: center;" class="uname d-flex" @click.stop="gotoPersonal(item , index)">
              <div :style="{  maxWidth: (itemWidth-100)+ 'px' }"
              style="overflow: hidden; white-space: nowrap;text-overflow: ellipsis;"
              >
                {{ item.uname }}
              </div>
              <b-img style="width: 26px; height: 14px;margin-right: 2px;margin-left: 2px;" 
                :src="require('../assets/img/waterfall/vip.png')"
                  fit="contain" v-if="item.ownerIsVip">
                <div slot="placeholder" class="image-slot"></div>
              </b-img>
            </div>
            
            <b-img style="width: 18px; height: 18px;margin-right: 2px"  v-if="index > 0"
              @click="like_edit( 1 , item  , index )"
              :src="require('../assets/img/waterfall/good_a.png')"
                v-show="item.like"
                fit="contain">
              <div slot="placeholder" class="image-slot"></div>
            </b-img>
            <b-img style="width: 18px; height: 18px;margin-right: 2px;" v-if="index > 0"
              @click="like_edit( 0 , item , index )"
              :src="require('../assets/img/waterfall/good.png')"
                v-show="!item.like"
                fit="contain">
              <div slot="placeholder" class="image-slot"></div>
            </b-img>

            
            <div v-if=" item.priority">{{ item.priority  > 0 ? item.priority  : '' }} </div>

          </div>
        </div>      
      </template>
    </waterfall>
  </div>
</template>

<script>
import common from '../utils/common'
import {  square_page_req , like_edit_req , square_detail } from "@/api/square";
import { growth_tasks_req , growth_publish_req } from "@/api/task";


export default {
  props: {
    //model 
    model : {
      type: String,
      default: '',
    },
  },  
  name : "Mywaterfall" ,
  data () {
    return {
      route_name : undefined , 
      imgList : [] , 
      pageNum : 1 ,
      pageSize : 30 , 
      isHover : false ,  
      hoverIdx : undefined , 
      columnType : localStorage.getItem("columnType") ? localStorage.getItem("columnType") : 2 ,  //分几列
      type : "1" , //1:最新2：最热
      show : false  , 
      itemWidth : 100 , 
      clientHeight : 100 , 
      last_id : undefined , //之前的id ，
      lastWidth : undefined , //之前的宽度
      noresizeAble : false , 
    }
  },
  computed:{
    tokenVuex() {
      // 存储位置： store.state.{模块名,在store/index.js中module下的}.{state中定义的属性}
      return this.$store.state.user.token
    } ,
    columnNum(){
      let _num  = 4 ;
      if( this.columnType == 1 ){
        _num = 4 ; 
      } else if( this.columnType == 2 ){
        _num = 6 ; 
      } else if( this.columnType == 3 ){
        _num = 7 ; 
      }
      return _num ; 
    } ,
  },
  mounted () {
    this.route_name = this.$route.name
    this.noresizeAble = true ; 
    this.imgList = [{
      url : 'https://boserai.com/replaceface.gif' ,
      uname : 'AI换脸'
    }] ;
    this.pageNum = 1 ;
    this.get_square_page( );
    this.itemWidth = (( (document.documentElement.clientWidth - 278)  / this.columnNum ) ) - 18 ; 
    // window.addEventListener('resize', this.resize, false )
    window.addEventListener('resize',   this.throttle(  this.resize , 300 )  , false );
    
    setTimeout(() => {
      this.show = true ;
      this.resize();
      this.noresizeAble = false; 
    }, 300);
    // this.get_img_list()
  },
  // 注销 window.onresize
  beforeDestroy() {
    window.removeEventListener('resize', this.resize)
  },
  methods:{
    get_square_page(){
      this.loading = true ; 
      square_page_req({
        pageNum : this.pageNum  ,
        pageSize : this.pageSize , 
        type : this.type  ,
        model : this.model ? this.model : undefined
      }).then( res => {
        this.imgList = this.imgList.concat( res.data.records ) ;
        this.loading = false  ; 
      }).catch().finally( ()=>{
        this.loading = false ; 
      })
    },
    loadwatermore(){
      this.pageNum++ ;
      this.get_square_page();
    } ,

    handleMouseOver( index ) {
      this.isHover = true;
      this.hoverIdx = index ; 
    },
    handleMouseOut( ) {
      this.isHover = false ;
      this.hoverIdx = undefined ; 
    },
    apply_click( item ){
      this.$store.commit('user/change_token') ;
      if( !this.tokenVuex ){
        this.loginFormShow = true ;
        this.$emit('loginFormShowChange', true);
        return;
      }
      let __request =  JSON.parse( item.request )
      __request._id = item.id ;
      if( item.category === 0 ){
        this.$router.push({
          name: "textImage", // ⚠️注：这里不能用path路径，只能用name【请对照router.js中的路由规则中的name项】，否则取不到传过去的数据
          params: __request
        });
      } else if( item.category === 1 ){
        let _request =  JSON.parse( item.request )
        _request.url = item.url ; 
        _request._id = item.id ; 
        this.$router.push({
          name: "imageToImage", // ⚠️注：这里不能用path路径，只能用name【请对照router.js中的路由规则中的name项】，否则取不到传过去的数据
          params: _request
        });
      }

    } ,
    imgitem_click( item  , index ){
      if( index == 0 ){
        this.$router.push({
          name: "ReplaceFace", // ⚠️注：这里不能用path路径，只能用name【请对照router.js中的路由规则中的name项】，否则取不到传过去的数据
        });
        return
      }
      // this.$store.commit('change_token') ;
      // if( !this.tokenVuex ){
      //   this.loginFormShow = true ;
      //   this.$emit('loginFormShowChange', true );
      //   return;
      // }
      this.last_id = item.id ; 
      let obj = JSON.parse( item.request ) ; 
      obj.category = item.category ;
      obj.url = item.url ; 
      obj.createdAt = item.createdAt ;
      obj.router = "explore"
      obj._id = item.id
      this.$router.push({
        name: "test", // ⚠️注：这里不能用path路径，只能用name【请对照router.js中的路由规则中的name项】，否则取不到传过去的数据
        params: obj
      });
    },
    typeToggle( type ){
      this.columnType = type ; 
      localStorage.setItem( "columnType" , type );
      this.resize();
    },
    resize() {

      // if( this.noresizeAble ){
      //   return ;
      // }
      this.imgList = [{
        url : 'https://boserai.com/replaceface.gif' ,
        uname : 'AI换脸' 
      }] ;
      this.pageNum = 1 ;
      this.get_square_page( );  
      // this.$waterfall.forceUpdate()   //forceUpdate
      this.show = false ;
      setTimeout(  ()=>{
        this.itemWidth = (( (document.documentElement.clientWidth - 278)  / this.columnNum ) ) - 18 ; 
        this.show = true ;
      } , 30)
    },
    typeInput(){
      if( this.loading  ){
        return ; 
      }
      this.resize();
    } ,
    like_edit( flag , item , index  ){
      if( this.loading == true  ) {
        return 
      }
      this.$store.commit('user/change_token') ;
      if( !this.tokenVuex ){
        this.loginFormShow = true ;
        this.$emit('loginFormShowChange', true);
        return;
      }
      this.loading = true ; 
      like_edit_req({
        imgId : item.id , 
        status : flag , 
        owner : item.uid , 
        imgMd5 : item.imgMd5
      }).then( res => {
        if( flag == 0 ){
          this.imgList[index].like = true ;
          this.imgList[index].priority += 1 ; 

          growth_tasks_req({type : 3 }).then( res1 => {
            if(  res1.data[0].total >= res1.data[0].count ){
              setTimeout( ()=>{
                this.$toast.open({ message: '点赞成功，3积分已到账', position: 'top', duration: 3000, type: 'success' });
              } , 1000 )
            }
          })

        } else {
          this.imgList[index].like = false  ;
          this.imgList[index].priority -= 1 ; 
        }
      }).catch(()=>{

      }).finally( () => {
        this.loading = false ; 
      })
    } , 
    gotoPersonal( item , index ){
      if( index == 0 ){
        this.$router.push({
          name: "ReplaceFace", // ⚠️注：这里不能用path路径，只能用name【请对照router.js中的路由规则中的name项】，否则取不到传过去的数据
        });
        return
      }

      let obj = {} ;
      obj.router = "personal"  ;
      obj.uid = item.uid ;
      this.$router.push({
        name: "test", // ⚠️注：这里不能用path路径，只能用name【请对照router.js中的路由规则中的name项】，否则取不到传过去的数据
        params: obj
      });
    },

    throttle(fn, time) {
      //3. 通过闭包保存一个 "节流阀" 默认为false
      let temp = false;
      return function() {
        //8.触发事件被调用 判断"节流阀" 是否为true  如果为true就直接trurn出去不做任何操作
        if (temp) {
          return;
        } else {
          //4. 如果节流阀为false  立即将节流阀设置为true
          temp = true; //节流阀设置为true
          //5.  开启定时器
          setTimeout(() => {
            //6. 将外部传入的函数的执行放在setTimeout中
            fn.apply(this, arguments);
            //7. 最后在setTimeout执行完毕后再把标记'节流阀'为false(关键)  表示可以执行下一次循环了。当定时器没有执行的时候标记永远是true，在开头被return掉
            temp = false;
          }, time);
        }
      };
    }

  },
  activated(){

    if( this.$store.state.user.needresize  ){
      this.$store.commit('user/change_size' , false   ) 
      this.resize();
    } else  if( (this.lastWidth != this.itemWidth)  ||  ( this.clientHeight != document.documentElement.clientHeight ) ){
      this.resize();
    }
    if( this.last_id ){
      square_detail( this.last_id ).then( res => {
        this.imgList.forEach( item => {
          if( item.id == this.last_id   ){
            item.like = res.data.like ;
            item.priority = res.data.priority
          }
        })
      }) 
    }
    this.show = true   
    
  }, 
  deactivated(){
    this.lastWidth = this.itemWidth ; 
    this.clientHeight = document.documentElement.clientHeight ;
    this.show = false ;
  },  
  watch: {
    model: function (val) {
      if(  val ) {
        // this.imgList = [] ;
        // this.pageNum = 1 ;
        // this.get_square_page( );

        var myInter = setInterval( () => {
          if( this.loading == false  ){
            clearInterval(myInter);
            this.imgList = [{
              url : 'https://boserai.com/replaceface.gif' ,
              uname : 'AI换脸'
            }] ;
              this.pageNum = 1 ;
              this.get_square_page( );
          }
        } , 50)
      }
    },

  }


}

</script>

<style lang="scss" scoped>


::v-deep .form-control {
  border: none;
}
::v-deep .form-control:focus {
  border-color: #fff;
  box-shadow : none
}
::v-deep .waterfull ._laebl {
  margin-bottom: 0;
}
::v-deep  .waterfull {
  .el-radio-group {
    background-color: #222337;
    border-radius: 20px;
  }
  .el-radio-button .el-radio-button__inner {
    border-radius: 20px ;
    background-color: #222337;
    border: 1px solid #222337;
    color: #fff;
  }  
  .is-active .el-radio-button__inner {
    background-color: #006FFF;
  }
}


.cont {
  padding: 60px 84px 0;
  // height: calc(100% );
  box-sizing: border-box;
}
.row {
  margin-left: 0;
  margin-right: 0;
}

.icon {
  width: 40%;
  min-width: 70px;
  min-height: 70px;
  padding-bottom: 40%;
  margin: 0 auto;
}


.home1 {
  margin-top: 77px;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  color: #fff;
} 

.home2 {
  margin-top: 42px;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;

  .item {
    box-sizing: border-box;
    height: 40px;
    width: 96px;
    border-radius: 20px;
    text-align: center;
    line-height: 40px;
  }
  .active {
    background-color: #fff;
    color : #fff ;
    font-weight: 500;
    height: 40px;
    width: 96px;
    background: #222337;
    border-radius: 20px;
    text-align: center;
    line-height: 40px;
    margin-right: 102px;
  }
}

.home3 {
  width: 1000px;
  justify-content: center;
  align-items: center;
  color: #fff;
  height: 66px;
  border-radius: 33px;
  background-color: #222337;
  margin: 24px auto 20px ;
  .l {
    justify-content: center;
    align-items: center;
    width: 72px;
    height: 66px;
  }
  .input {
    background-color: #222337;
    color: #fff;
    padding: 0;
  }
  .input::placeholder {
    color: #49495A;
  }


  .search-btn {
    height: 66px;
    width: 92px;
    align-items: center;
    justify-content: center;
  }
}

.waterfall_title {
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 33px;
  margin-top: 43px;
  margin-bottom: 16px;
}
.cell-item {
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 14px;
  position: relative;
  .btn {
    display: none;
  }
  .botton_cont {
    position: absolute;
    padding-bottom: 8px;
    padding-left: 8px;
    padding-right: 8px;
    height: 30px;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: center;
    font-size: 14px;
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%);
    .uname {
      overflow: hidden;//多出部分隐藏
      white-space: nowrap;//一行显示
      text-overflow: ellipsis;//是否显示省略号
      padding-right: 5px;
    }
  }
}
.cell-item:hover img {
    transform: scale(1.15);
    transition : all 0.5s ;
} 
.cell-item:hover {
  .btn {
    display: block;
    position: absolute;
    top: 8px;
    right: 8px;
    width: 66px;
    height: 34px;
    line-height: 34px;
    background: #006FFF;
    border-radius: 17px;
    text-align: center;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    padding: 0;
  }
}
</style>