<template>
  <!-- vueCropper 剪裁图片实现-->
  <el-dialog :visible.sync="dialogUploadAvaVisible" class="dialog crop-dialog" append-to-body :before-close="handleClose"
    :show-close="false" width="600px" :close-on-click-modal="false" :close-on-press-escape="false">
    <div class="title d-flex" style="padding: 14px;justify-content: flex-end;">
      <el-image style="width: 24px; height: 24px;cursor: pointer;"
        @click="handleClose()"
        :src="require('../assets/img/recharge/close.png')"
        fit="contain" >
        <div slot="placeholder" class="image-slot" style="background-color: rgb(17, 18, 37);"></div>
      </el-image>
    </div>

    <div class="cropper-content">
      <div class="cropper" style="text-align:center">
        <vueCropper ref="cropper" :img="option.img" :outputSize="option.size" :outputType="option.outputType" :info="true"
          :full="option.full" :canMove="option.canMove" :canMoveBox="option.canMoveBox" :original="option.original"
          :autoCrop="option.autoCrop" :fixed="option.fixed" :fixedNumber="option.fixedNumber"
          :centerBox="option.centerBox" :infoTrue="option.infoTrue" :fixedBox="option.fixedBox"
          :autoCropWidth="option.autoCropWidth" :autoCropHeight="option.autoCropHeight" @cropMoving="cropMoving" />
      </div>
    </div>

    <div class="d-flex" style="justify-content: flex-end;padding: 14px 14px 22px;">
      <el-button round style="background: #25293C;color: #666666;border: 1px solid #25293C;width: 130px;"
        @click="handleClose">取消</el-button>
      <el-button type="primary" round style="background: #006FFF;border: 1px solid #006FFF;color: #fff;width: 130px;"
        autofocus @click="submit">确定</el-button>
    </div>

  </el-dialog>
</template>

<script>
import { user_photo_req } from "@/api/user";

export default {
  name: "Cropper",
  props: {
    dialogUploadAvaVisible: {
      type: Boolean,
      default: false,
    },
    option : {
      type: Object,
      default: {} ,
    }
  },
  data() {
    return {
      isPreview: false,
      dialogVisible: false,
      previewImg: '', // 预览图片地址
      // 裁剪组件的基础配置option
      // 防止重复提交
      loading: false
    }
  },
  methods: {
    handleClose(done) {
      this.$emit('update:dialogUploadAvaVisible', false) // 传递更新数值
    },
    // 上传按钮 限制图片大小和类型
    handleChangeUpload(file, fileList) {
      const isJPG = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
        return false
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
        return false
      }
      // 上传成功后将图片地址赋值给裁剪框显示图片
      this.$nextTick(async () => {
        // base64方式
        // this.option.img = await fileByBase64(file.raw)
        this.option.img = URL.createObjectURL(file.raw)
        this.loading = false
        this.dialogVisible = true
      })
    },
    // 放大/缩小
    changeScaleHandle(num) {
      num = num || 1;
      this.$refs.cropper.changeScale(num);
    },
    // 左旋转
    rotateLeftHandle() {
      this.$refs.cropper.rotateLeft();
    },
    // 右旋转
    rotateRightHandle() {
      this.$refs.cropper.rotateRight();
    },
    // 下载
    downloadHandle(type) {
      let aLink = document.createElement('a')
      aLink.download = 'author-img'
      if (type === 'blob') {
        this.$refs.cropper.getCropBlob((data) => {
          let downImg = window.URL.createObjectURL(data)
          aLink.href = window.URL.createObjectURL(data)
          aLink.click()
        })
      } else {
        this.$refs.cropper.getCropData((data) => {
          let downImg = data;
          aLink.href = data;
          aLink.click()
        })
      }
    },
    // 清理图片
    clearImgHandle() {
      this.option.img = ''
    },
    // 截图框移动回调函数
    cropMoving(data) {
      // 截图框的左上角 x，y和右下角坐标x，y
      // let cropAxis = [data.axis.x1, data.axis.y1, data.axis.x2, data.axis.y2]
      // console.log(cropAxis)
    },
    finish() {
      // 获取截图的 blob 数据
      this.$refs.cropper.getCropBlob((blob) => {
        this.loading = true
        this.dialogVisible = false
        this.previewImg = URL.createObjectURL(blob)
        this.isPreview = true
      })
      // 获取截图的 base64 数据
      // this.$refs.cropper.getCropData(data => {
      //     console.log(data)
      // })
    } ,
    async submit(){
      this.$refs.cropper.getCropBlob(async (fileData) => {
        let formData = new FormData();
        formData.append('file',fileData,"ava.png");
        const loading = this.$loading({
          lock: true,
          text: '上传中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)' ,
          customClass : "my_loading" 
        });




        let { data , success } = await user_photo_req( formData );
        loading.close();
        if( success ) {
          this.$toast.open({ message: '已上传', position: 'top', duration: 3000, type: 'success' });
        } else {
          this.$toast.open({ message: '上传失败,请重试', position: 'top', duration: 3000, type: 'error' });
        }
        this.handleClose();
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  min-width: 600px;
  // position: relative;
  // background-color: palegreen;
  background-color: #1B1D2E !important;
  box-shadow: none;

  display: flex;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /*height:600px;*/
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);
  border-radius: 20px;

  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    padding: 0;

    .cont {
      width: 864px;
      height: 623px;
      padding: 20px 34px;
      box-sizing: border-box;

      .title {
        height: 30px;
        line-height: 30px;
        width: 100%;
        position: relative;
        justify-content: center;
        align-items: center;
        font-size: 21px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 29px;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
        .title_r {
          text-align: right;
          flex: 1;
        }
      }

    }
  }

  // {
  //   background-image: url("../assets/img/textform/bg.png") !important;
  // }

  .cropper-box  {
    background-color: #111225 !important;
  }
  .vue-cropper {
    background-color: #111225 !important;
    background-image: none !important ;
  }
}


.cropper-content {
  background-color: #1B1D2E;
  .cropper {
    width: 572px;
    height: 572px;
    margin: 0 14px;
  }
}
</style>