<template>
  <el-dialog class="dialog" :visible.sync="dialogFeedbackVisible" :before-close="handleClose" :show-close="false"
    append-to-body width="864px">
    <div class="cont" v-if="curPage == 1 ">
      <div class="title d-flex">
        <el-image style="width: 120px; height: 14px;margin-right: 5px;cursor: pointer;"
          :src="require('../assets/img/feedback/left.png')" fit="contain">
          <div slot="placeholder" class="image-slot"></div>
        </el-image>
        <span style="margin: 0 7px;">意见反馈</span>
        <el-image style="width: 120px; height: 14px;margin-right: 5px;cursor: pointer;"
          :src="require('../assets/img/feedback/right.png')" fit="contain">
          <div slot="placeholder" class="image-slot"></div>
        </el-image>

        <el-image style="width: 24px; height: 24px;cursor: pointer;position: absolute;right: -20px;top: -6px;"
          @click="handleClose()" :src="require('../assets/img/recharge/close.png')" fit="contain">
          <div slot="placeholder" class="image-slot" style="background-color: rgb(17, 18, 37);"></div>
        </el-image>
      </div>

      <div v-if="!isSuccess && !isFail">
        <div class="sub_title">您好，很抱歉我们暂时无法为您提供服务，如需帮助请留言，我们将尽快联系并解决您的问题！</div>
        <div class="d-flex subject">
          问题类型
          <el-image style="width: 16px; height: 16px;" :src="require('../assets/img/feedback/must.png')" fit="contain">
            <div slot="placeholder" class="image-slot" style="background-color: rgb(17, 18, 37);"></div>
          </el-image>
        </div>
        <div class="d-flex type">
          <div class="type_item" @click="type = '1'" :class="{ active: type == '1' }">会员问题</div>
          <div class="type_item" @click="type = '2'" :class="{ active: type == '2' }">产品建议</div>
          <div class="type_item" @click="type = '3'" :class="{ active: type == '3' }">我要咨询</div>
          <div class="type_item" @click="type = '0'" :class="{ active: type == '0' }">其他</div>
        </div>
        <div class="d-flex subject">
          反馈内容
          <el-image style="width: 16px; height: 16px;" :src="require('../assets/img/feedback/must.png')" fit="contain">
            <div slot="placeholder" class="image-slot" style="background-color: rgb(17, 18, 37);"></div>
          </el-image>
        </div>

        <div class="d-flex text-cont" style="align-items: center;margin-bottom: 14px; ">
          <div style="flex:1">
            <el-input resize="none" type="textarea" :autosize="{ minRows: 4, maxRows: 4 }" placeholder="请填写您的具体描述"
              v-model="msg" style="background-color: #222337;"></el-input>
          </div>
        </div>
        <div class="d-flex" style="margin-bottom: 8px;">
          <el-upload class="avatar-uploader" :action="baseUrl + '/guestbook/upload'" :show-file-list="false"
            :headers="myHeaders" :before-upload="beforePicUpload"
            :on-success="(response, file, fileList) => uploadSuccess(response, file, fileList, idx)"
            v-for="( item, idx ) in picsList">
            <el-image :src="require('../assets/img/feedback/close.png')" fit="contain"
              style="width: 20px; height: 20px;cursor: pointer;position: absolute;right: 4px;top: 4px;z-index: 9"
              v-if="picsList[idx]" @click.stop="deleteClick(idx)">
              <div slot="placeholder" class="image-slot"></div>
            </el-image>
            <div v-if="picsList[idx]" style="">
              <el-image :src="picsList[idx]" class="avatar" fit="contain">
                <div slot="placeholder" class="image-slot"></div>
              </el-image>
            </div>
            <div v-else>
              <div style="text-align: center;">
                <i class="el-icon-plus avatar-uploader-icon"></i>
              </div>
              <div
                style="text-align: center;font-size: 13px;font-family: PingFangSC-Regular, PingFang SC;color: #CACAD4;">
                上传图片</div>
            </div>
          </el-upload>
        </div>

        <div style="font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;color: #999999;margin-bottom: 13px;">
          温馨提示：支持jpg、png、gif、bmg格式的图片，可上传5张，单张最大2M</div>

        <div class="d-flex subject">
          联系方式
        </div>
        <div class="d-flex text-cont" style="align-items: center;margin-bottom: 20px;min-height: 40px;">
          <div style="flex:1">
            <el-input resize="none" type="textarea" :autosize="{ minRows: 1, maxRows: 1 }" placeholder="请填写您的手机号码"
              v-model="tel" style="background-color: #222337;"></el-input>
          </div>
        </div>
        <div class="d-flex" style="justify-content: flex-end;">
          <div style="flex:1">
            <el-button round style="border: 1px solid #006FFF;color: #006FFF;background-color: #1B1D2E;" @click="toggleList">反馈记录</el-button>
          </div>
          <el-button round style="background: #25293C;color: #666666;border: 1px solid #25293C;width: 130px;"
            @click="handleClose">取消</el-button>
          <el-button type="primary" round style="background: #006FFF;border: 1px solid #006FFF;color: #fff;width: 130px;"
            autofocus @click="submit">提交</el-button>
        </div>
      </div>
      <div v-else-if="isSuccess">
        <div style="text-align: center;margin-top: 120px;">
          <el-image style="width: 88px; height: 88px;"
            :src="require('../assets/img/feedback/success.png')" fit="contain">
            <div slot="placeholder" class="image-slot" style="background-color: rgb(17, 18, 37);"></div>
          </el-image>
        </div>
        <div style="text-align: center;margin-top: 24px;font-size: 15px;font-family: PingFangSC-Regular, PingFang SC;color: #CACAD4;">
          提交成功 
        </div>
        <div style="text-align: center;margin-top: 10px;font-size: 15px;font-family: PingFangSC-Regular, PingFang SC;color: #CACAD4;">
          我们将尽快联系并解决您的问题，感谢您的支持！
        </div>
      </div>
      <div v-else-if="isFail">
        <div style="text-align: center;margin-top: 120px;">
          <el-image style="width: 88px; height: 88px;"
            :src="require('../assets/img/feedback/fail.png')" fit="contain">
            <div slot="placeholder" class="image-slot" style="background-color: rgb(17, 18, 37);"></div>
          </el-image>
        </div>
        <div style="text-align: center;margin-top: 24px;font-size: 15px;font-family: PingFangSC-Regular, PingFang SC;color: #CACAD4;">
          提交失败 
        </div>
        <div style="text-align: center;margin-top: 10px;font-size: 15px;font-family: PingFangSC-Regular, PingFang SC;color: #CACAD4;">
          请检查网络是否连接正常
        </div>
      </div>
    </div>
    <div class="cont" v-else-if="curPage == 2">
      <div class="title d-flex">
        <el-image style="width: 24px; height: 24px;cursor: pointer;position: absolute;left: 0;top: 0;"
          :src="require('../assets/img/feedback/back.png')" fit="contain" @click="goBack">
          <div slot="placeholder" class="image-slot" style="background-color: rgb(17, 18, 37);"></div>
        </el-image>
        <span style="margin: 0 7px;">反馈记录</span>
        <el-image style="width: 24px; height: 24px;cursor: pointer;position: absolute;right: -20px;top: -6px;"
          @click="handleClose()" :src="require('../assets/img/recharge/close.png')" fit="contain">
          <div slot="placeholder" class="image-slot" style="background-color: rgb(17, 18, 37);"></div>
        </el-image>
      </div>

      <div style="margin-bottom: 18px;margin-top: 20px;">
        <el-button size="mini" round @click="clickType(idx , true )" style="background-color: #1B1D2E;color: #CACAD4;border: none;"
        v-for="(item , idx ) in feedTypeData"
        :style="{ backgroundColor: item.flag ? '#006FFF' : '#272a3b' , color : item.flag ? '#FFFFFF' : '#CACAD4'}"
        > {{ item.name }}
        </el-button>
      </div>
      <!--  v-for="i in replyList" -->
      <div class="replyListCont" v-infinite-scroll="replyload" style="overflow:auto;height: 480px;">
        <div class="replyItem" v-for="replyitem in replyList">
          <div class="d-flex">
            <div style="flex:1" class="d-flex">
              <span style="font-size: 16px;font-weight: 500;color: #FFFFFF;line-height: 22px;">
              {{ replyitem.type == 1 ? '会员问题' : '' }}
              {{ replyitem.type == 2 ? '产品建议' : '' }}
              {{ replyitem.type == 3 ? '我要咨询' : '' }}
              {{ replyitem.type == 0 ? '其他' : '' }}
              </span>  
              <div class="d-flex" style="align-items: center;"  :style="{color:( replyitem.status==1? '#12D107':'#F5B109')}"  > 
                <div style="width: 8px;height: 8px;border-radius: 50%;margin-left: 10px;margin-right: 4px;"
                class="icon"
                :class='{activedIcon: replyitem.status==1 }'
                ></div> {{  replyitem.status== 1 ? '已完结' : '跟进中'  }}
              </div>
            </div>
            <div class="date">
              {{  replyitem.createdAt }}
            </div>
          </div>
          <div class="line" style="margin-top: 12px;">
            {{ replyitem.msg }}
          </div>
          <div class="line" style="margin-top: 1px;margin-bottom: 9px;">
            联系方式：{{ replyitem.tel ? replyitem.tel : '未填写' }}
          </div>

          <div class="d-flex" style="margin-bottom: 8px;">
            <el-image :src="item" fit="contain"
              style="width: 70px; height: 70px;cursor: pointer;border-radius: 10px;margin-right: 8px;"
              v-for="item in replyitem.pics ">
              <div slot="placeholder" class="image-slot"></div>
            </el-image>
          </div>

          <div class="answer" v-if="replyitem.status == 1">
            <div class="d-flex" style="margin-bottom: 6px;">
              <div style="flex:1">回复：</div>
              <div>{{  replyitem.replyAt }}</div>
            </div>
            <div>
              {{  replyitem.reply ? replyitem.reply: "您的反谈我们已经收到，如有必要我们会通过您留下的联系方式与您联系" }}
            </div>
          </div>
        </div>
      </div>



    </div>
  </el-dialog>
</template>

<script>
import { getBaseUrl, getToken } from "@/utils/bose";
import { guestbookSaveReq , guestbook_page_req } from "@/api/feedback"



export default {
  props: {
    dialogFeedbackVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      type: "1",
      msg: undefined,
      picsList: [undefined, undefined, undefined, undefined, undefined],
      baseUrl: getBaseUrl(),
      myHeaders: {
        'x-token': getToken(),
      },
      tel: undefined,

      isSuccess: false,
      isFail: false ,

      curPage : 1 ,  // 1 form 2 list 

      feedTypeData : [{
        status : 2 , 
        name : "全部状态" ,
        flag : true , 
      } ,{
        status : 0 , 
        name : "跟进中" ,
        flag : false  , 
      },{
        status : 1 , 
        name : "已完结" ,
        flag : false  , 
      }] ,
      replyList : [] ,
      pageNum : 1 , 
      pageSize : 10 , 
      replystatus : 2 , 
      replyFinished : false , 
      replyLoading : false , 
    }
  },
  methods: {
    handleClose(done) {
      this.$emit('update:dialogFeedbackVisible', false) // 传递更新数值
    },
    beforePicUpload(file) {
      const isJPG = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp'].indexOf(file.type) > -1 ? true : false;
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$toast.open({
          message: "请检查上传图片的格式",
          position: 'top',
          duration: 3000,
          type: 'error',
        });
        return false;
      }
      if (!isLt2M) {
        this.$toast.open({
          message: "上传图片大小不能超过 2MB!",
          position: 'top',
          duration: 3000,
          type: 'error',
        });
        return false;
      }
      return isJPG && isLt2M;
    },
    uploadSuccess(response, file, fileList, idx) {
      this.$set(this.picsList, idx, response.data)
    },
    deleteClick(idx) {
      this.$set(this.picsList, idx, undefined)
    },
    submit() {
      if( !this.msg ){
        this.$toast.open({
          message:  "请输入留言哦" ,
          position: 'top',
          duration : 3000,
          type: 'error',
        });
        return ;
      }
      let pics = [];
      this.picsList.forEach(ele => {
        if (ele) {
          pics.push(ele)
        }
      })
      guestbookSaveReq({
        tel: this.tel,
        msg: this.msg,
        pics: pics,
        type: this.type,
      }).then(res => {
        this.isSuccess = true;
      }).catch(e => {
        this.isFail = true;
      })

    },
    toggleList(){
      this.curPage = 2 ;
      this.pageNum = 1 ;
      this.replyList = [] ;
      this.replyFinished = false 
      // this.guestbookPage(); 
    },
    goBack(){
      this.curPage = 1 ;
      this.pageNum = 1 ;
      this.replyList = [] ;
      this.replyFinished = false 
    },
    clickType( idx ){
      this.feedTypeData.forEach( val => {
        val.flag = false  ;
      })
      this.feedTypeData[idx].flag = true ;
      this.replystatus = this.feedTypeData[idx].status ;
      this.pageNum = 1 ;
      this.replyList = [] ;
      this.replyFinished = false 
      this.replyload() ;
    },
    guestbookPage(){
      guestbook_page_req({
        pageNum : this.pageNum , 
        pageSize : this.pageSize , 
        status : this.replystatus , 
      }).then( res => {
        this.replyLoading = false  ;
        this.replyList = this.replyList.concat( res.data.records ) ;
        this.pageNum++;
        if( res.data.current >= res.data.pages ){
          this.replyFinished = true ;
        }
      })
    },
    replyload () {
      // this.pageNum++ ;
      if( this.replyFinished || this.replyLoading ) return ;
      this.replyLoading  = true ;
      this.guestbookPage();

    }
  }
}
</script>

<style lang="scss" scoped>  
::v-deep .el-dialog {
    min-width: 864px;
    // position: relative;
    // background-color: palegreen;
    background-color: #1B1D2E !important;
    box-shadow: none;

    display: flex;
    margin: 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /*height:600px;*/
    max-height: calc(100% - 30px);
    max-width: calc(100% - 30px);
    border-radius: 20px;

    .el-dialog__header {
      display: none;
    }

    .el-dialog__body {
      padding: 0;

      .cont {
        width: 864px;
        height: 623px;
        padding: 20px 34px;
        box-sizing: border-box;

        .title {
          height: 30px;
          line-height: 30px;
          width: 100%;
          position: relative;
          justify-content: center;
          align-items: center;
          font-size: 21px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 29px;
          text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
        }

        .sub_title {
          width: 100%;
          font-size: 15px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #CACAD4;
          line-height: 21px;
          margin-top: 9px;
          margin-bottom: 14px;
          text-align: center;
        }

        .subject {
          margin-bottom: 13px;
          align-items: center;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
        }

        .type {
          justify-content: space-between;
          margin-bottom: 22px;

          .type_item {
            width: 190px;
            height: 40px;
            line-height: 38px;
            background: rgba(155, 184, 220, 0.08);
            border-radius: 10px;
            text-align: center;
            font-size: 15px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #CACAD4;
            cursor: pointer;
            border: 1px solid rgba(155, 184, 220, 0.08);
            box-sizing: border-box;
          }

          .type_item.active {
            background: rgba(0, 111, 255, 0.15);
            border: 1px solid #006FFF;
            color: #006FFF;
          }
        }

        .text-cont {
          box-sizing: border-box;
          border: none;
          max-height: 124px;
          padding: 8px 0;
          min-height: 66px;
          background: #111225;
          border-radius: 10px;

          textarea {
            border: none;
            resize: none;
            cursor: pointer;
          }
        }

        .upload_item {
          width: 70px;
          height: 70px;
          border-radius: 10px;

        }
      }
    }
  }




  ::v-deep .el-textarea.is-disabled .el-textarea__inner {
    background: #111225;
  }

  ::v-deep .el-textarea__inner {
    background: #111225;
    border: none;
    color: #fff;

    &::placeholder {
      color: #666;
    }

    &::-webkit-input-placeholder {
      /* WebKit browsers 适配谷歌 */
      color: #666;
    }

    &:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 适配火狐 */
      color: #666;
    }

    &::-moz-placeholder {
      /* Mozilla Firefox 19+ 适配火狐 */
      color: #666;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10+  适配ie*/
      color: #666;
    }
  }




  ::v-deep .avatar-uploader .el-upload {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 70px;
    height: 70px;
    border-radius: 10px;
    background: rgba(155, 184, 220, 0.08);
    border-radius: 10px;
    color: #CACAD4;
    margin-right: 12px;
  }

 .replyListCont {
  .replyItem {
    padding: 12px 16px;
    box-sizing: border-box ;
    background: #111225;
    margin-bottom: 20px;
    .date {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #9696A2;
      line-height: 20px;
    }
    .line {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #CACAD4;
      line-height: 20px;
    }
    .answer {
      background: #1B1D2E;
      border-radius: 10px;
      padding: 10px 14px;
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #9696A2;
      line-height: 18px;
      // background: #111225;
      margin-top: 16px;
      background: #1B1D2E;

    }
    .icon {
      background: #F5B109
    }
    .activedIcon {
      background : #12D107
    }
  }
 } 
</style>