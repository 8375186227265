<template>
  <div v-if="!hasnewrecharge">
    <el-dialog class="dialog" :visible.sync="newMemberRechargeShow " :before-close="handleClose" :show-close="false" v-if="!isend && newMemberRechargeShow && ( m > 0 || s > 0 )">
      <div class="light rotate">

      </div>
      <div class="main" @click="gotoRecharge">
        <div class="close" @click.stop="handleClose"></div>
        <!-- <div class="btn_cont">
          <div class="_btn _btn1" @click="handleClose">残忍离开</div>
          <div class="_btn _btn2" @click="gotoRecharge">前往抢购</div>
        </div> -->
        <div class="countdown">
          <div class="top">距离结束</div>
          <div class="bot">
            <div class="botitem">{{   m  }}</div>:<div class="botitem">{{  s  }}</div>:<div class="botitem">{{  ms  }}</div>
          </div>
        </div>
      </div>
    </el-dialog>

    <div v-if="!isend && !newMemberRechargeShow " class="fixed_right" @click="gotoRecharge">
      <div class="redbag2"></div>
      <div class="bot">
        <div class="botitem">{{   m  }}</div>:<div class="botitem">{{  s  }}</div>:<div class="botitem">{{  ms  }}</div>
      </div>
    </div>

  </div>


</template>


<script>
import { user_invoke_req , user_account_req , event_log_req ,  user_countdown_req } from "@/api/user"



export default {
  props: {
    newMemberRechargeShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      timer : null , 
      // m : undefined , 
      // s : undefined , 
      // ms : undefined , 
      isend : false , 
    };
  },
  methods: {
    handleClose(done) {
      this.$emit('update:newMemberRechargeShow', false) // 传递更新数值
    },
    gotoRecharge(){
      this.$emit('update:newMemberRechargeShow', false) // 传递更新数值
      this.$emit('gotoRecharge');
    },
    countTime( now , end ) {
      let _this = this ; 
      var leftTime = end - now  ; //时间差                              
      var d, h, m, s, ms;
      if(leftTime > 0 ) {
        m = Math.floor(leftTime / 1000 / 60 % 60);
        s = Math.floor(leftTime / 1000 % 60);
        ms = Math.floor(Math.random()*90) + 10;

        if(s < 10) {
          s = "0" + s;
        }
        if(m < 10) {
          m = "0" + m;
        }

        this.$store.commit('user/changeM_S_MS' , {
          m : m ,
          s : s  ,
          ms : ms 
        }) 

        // console.log( _this )
        setTimeout(  ()=>{  this.countTime( new Date().getTime() , end )  }    , 100 );

      } else {
        this.isend = true ; 
      }

    },
  },
  computed: {
    m () {
      return this.$store.state.user.m
    },
    s () {
      return this.$store.state.user.s
    },
    ms () {
      return this.$store.state.user.ms
    } ,
    hasnewrecharge(){
      return this.$store.state.user.hasnewrecharge
    }


  },
  mounted() {

    // const { data : tdata , success: tsuccess } =  user_countdown_req();
    // console.log( new Date().getTime()  + tdata*1000 );
  },
  watch : {
    newMemberRechargeShow(val){
      if( val  ){
        user_countdown_req().then( res => {
          if( res.data == 0){
            this.isend = true   ;
          } else {
            this.countTime( new Date().getTime() , new Date().getTime()  + res.data*1000  )  
          } 
          
        })
      }

      // const { data : tdata , success: tsuccess } = await user_countdown_req();
      // if( tdata > 0 ){
      //   let guoqiTime = new Date(  new Date().getTime() + tdata*1000)  ;  

      // }
    }
  }
};
</script>

<style lang="scss" scoped>  
  ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #006FFF;
    border-color: #006FFF;
    border-radius: 50%;
  }

  ::v-deep .el-checkbox__input .el-checkbox__inner {
    border-color: #fff;
    border-radius: 50%;
  }

  ::v-deep .el-dialog {
    width: 618px;
    height: 618px;
    position: relative;
    // background-color: palegreen;
    background-color: rgba(0, 0, 0, 0) !important;
    // background-image:url("../assets/img/recharge/light.png") !important;
    // background-size: 100%;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /*height:600px;*/
    max-height: calc(100% - 30px);
    max-width: calc(100% - 30px);

    .light {
      background-image:url("../assets/img/recharge/light.png") !important;
      background-size: 100%;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: -1 ;
    }
  }
  ::v-deep .el-dialog::after {
    
  }

  ::v-deep .el-dialog .el-dialog__body {
    flex: 1;
    overflow: auto;
    padding: 0;
  }
  ::v-deep .el-dialog .el-dialog__header {
    padding: 0;
  }

  //::v-deep .el-dialog
  // ::v-deep .el-dialog::-webkit-scrollbar-thumb {  
  //   background-color: #000;
  // } 


  ::v-deep .el-dialog .main {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 99;
    background-image:url("../assets/img/recharge/redbag.png") !important;
    background-size: 100% 90%;
    background-repeat: no-repeat;
    // padding: 40px;
    box-sizing: border-box;
    // background-color: rgba(0, 0, 0, 0.4);
    border-radius: 33px;
    // backdrop-filter: blur(8px);
    .btn_cont {
      position: absolute;
      left: 205px;
      bottom: 180px;
      width: 284px;
      height: 50px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      ._btn {
        width: 134px;
        height: 50px;
        line-height: 50px;
        border-radius: 30px;
        text-align: center;
        font-size: 20px;
        font-weight: 600;        
        color: #05254D;
        border-radius: 30px;
        cursor: pointer;
        &._btn1 {
          background: #CCE2FF;
        }
        &._btn2 {
          background: linear-gradient(315deg, #006FFF 0%, #72E3F5 100%);
        }
      }
    }
    .close {
      background-image:url("../assets/img/recharge/close2.png") !important;
      background-size: 100% 100%;
      width: 30px;
      height: 30px;
      position: absolute;
      right: 57px;
      top: 71px;
    }
    .title {
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      margin-top: 26px;
      position: relative;
    }

    .line_out {
      position: relative;

      .icon {
        position: absolute;
        top: 15px;
        left: 18px;
      }
    }

    .line {
      width: 370px;
      height: 50px;
      background: rgba(34, 35, 55, 0);
      border-radius: 33px;
      box-sizing: border-box;
    }

    .el-input__inner {
      height: 50px;
      border-radius: 33px;
      border: 2px solid #7D7D9B;
      background: rgba(34, 35, 55, 0);
      padding: 0 120px 0 50px;
      color: #fff;
    }

    .el-input__inner:focus {
      border-color: #006FFF;
    }

    .protocol {
      margin-top: 22px;
    }

    .code_error .el-input__inner {
      border-color: red;
      color: red;
    }

    .countdown {
      position: absolute;
      bottom: 23px;
      left: 50%;
      width: 130px;
      height: 60px ;
      margin-left: -65px;
      .top {
        font-weight: bold;
        font-size: 18px;
        color: #EF4545;
        line-height: 27px;
        text-align: center;
        margin-bottom: 4px;
      }
      .bot {
        display: flex;
        color: #fff;
        justify-content: space-around;
        .botitem {
          width: 28px;
          height: 28px;
          line-height: 28px;
          background: #FFFFFF;
          border-radius: 4px;
          text-align: center;
          font-weight: 500;
          font-size: 18px;
          color: #EF4545;
        }
      }
    }
  }


  .rotate {
    animation: rotate 5s linear infinite;
    -webkit-animation: rotate 5s linear infinite;
  }

  @keyframes rotate {
      0% {
          transform: rotate(0);
      }

      25% {
          transform: rotate(90deg);
      }

      50% {
          transform: rotate(180deg);
      }

      75% {
          transform: rotate(270deg);
      }

      100% {
          transform: rotate(360deg);
      }
  }

  @-webkit-keyframes rotate {
      0% {
          transform: rotate(0);
      }

      25% {
          transform: rotate(90deg);
      }

      50% {
          transform: rotate(180deg);
      }

      75% {
          transform: rotate(270deg);
      }

      100% {
          transform: rotate(360deg);
      }
  }

  .fixed_right {
    position: fixed;
    right: 7px ;
    bottom: 90px;
    width : 240px ;
    height: 290px;
    .redbag2 {
      background-image:url("../assets/img/recharge/redbag2.png") !important;
      background-size: 100% 100%;
      width: 240px;
      height: 262px; 
    }
    .bot {
        display: flex;
        width: 128px;
        margin: 0 auto ;
        margin-top: -10px;
        color: #fff;
        justify-content: space-around;
        .botitem {
          width: 28px;
          height: 28px;
          line-height: 28px;
          background: #FFFFFF;
          border-radius: 4px;
          text-align: center;
          font-weight: 500;
          font-size: 18px;
          color: #EF4545;
        }
      }
    
  }
</style>